import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { format, parseISO } from 'date-fns';
import * as _ from 'lodash-es';
import moment from 'moment';
import { TaskConfigDto, TaskConfigRepeatConfigTypeDto, TaskConfigRepeatDto, TaskConfigRepeatTypeDto } from '../../../gapicon/dto.module';
import { PhoenixTaskConfigDialog } from './classes/phoenix-task-config-dialog';
import { PhoenixTaskConfigDialogAnnuallyService } from './phoenix-task-config-dialog-annually/phoenix-task-config-dialog-annually.service';
import { PhoenixTaskConfigDialogDailyService } from './phoenix-task-config-dialog-daily/phoenix-task-config-dialog-daily.service';
import { PhoenixTaskConfigDialogMonthlyService } from './phoenix-task-config-dialog-monthly/phoenix-task-config-dialog-monthly.service';
import { PhoenixTaskConfigDialogOnceService } from './phoenix-task-config-dialog-once/phoenix-task-config-dialog-once.service';
import { PhoenixTaskConfigDialogWeeklyService } from './phoenix-task-config-dialog-weekly/phoenix-task-config-dialog-weekly.service';



@Component({
  selector: 'phoenix-task-config-dialog',
  templateUrl: './phoenix-task-config-dialog.component.html',
  styleUrls: ['./phoenix-task-config-dialog.component.scss'],
})
export class PhoenixTaskConfigDialogComponent {
  public repeatType: typeof TaskConfigRepeatTypeDto = TaskConfigRepeatTypeDto;

  public constructor(
    @Inject(MAT_DIALOG_DATA) public data: PhoenixTaskConfigDialog,
    private dialog: MatDialog,
    private onceService: PhoenixTaskConfigDialogOnceService,
    private dailyService: PhoenixTaskConfigDialogDailyService,
    private weeklyService: PhoenixTaskConfigDialogWeeklyService,
    private monthlyService: PhoenixTaskConfigDialogMonthlyService,
    private annuallyService: PhoenixTaskConfigDialogAnnuallyService,
    private translate: TranslateService,
  ) {
  }

  public deleteConfiguration(taskConfig: TaskConfigDto): void {
    _.pull(this.data.taskConfigs, taskConfig);
  }

  public editConfiguration(taskConfig: TaskConfigDto): void {
    this.openDialog(taskConfig.repeat ? taskConfig.repeat.type : undefined, taskConfig);
  }

  public getPanelContent(taskConfigDto: TaskConfigDto): string[] {
    const panelContentStrings: string[] = [];
    let formattedDate = 'Invalid Date';

    try {
      if (taskConfigDto.startDate) {
        const date = this.parseDate(taskConfigDto.startDate);
        formattedDate = format(date, 'yyyy-MM-dd HH:mm');
      }
    } catch (error) {
      console.error('Error parsing date in getPanelContent:', error);
    }

    panelContentStrings.push(
      this.translate.instant('TASKCONFIG.PANEL.1').concat(' ',
        this.getRepititionString(taskConfigDto.repeat), ' ',
        this.translate.instant('TASKCONFIG.PANEL.2'), ' ',
        //format(parseISO(taskConfigDto.startDate.toString()), 'yyyy-MM-dd HH:mm'), ' ',
        formattedDate, ' ',
        this.translate.instant('TASKCONFIG.PANEL.3'), ' ',
        this.getDurationStringPrecise(moment.duration(taskConfigDto.duration, 'seconds')), ' ',
        this.translate.instant('TASKCONFIG.PANEL.4'), ' '),
      this.translate.instant('TASKCONFIG.PANEL.6').concat(' ',
        this.userGroupsToString(taskConfigDto.assignedUserGroupIds)));

    _.forEach(this.getExtendedRepititionStrings(taskConfigDto.repeat), (s: string) => panelContentStrings.push(s));

    return panelContentStrings;
  }
  userGroupsToString(assignedUserGroupIds: string[]): string {
    return assignedUserGroupIds ? this.data.usergroups.filter(ug => assignedUserGroupIds.includes(ug.id)).map(ug => ug.name).join(',') : '';
  }


  /*
  public getPanelDescription(taskConfigDto: TaskConfigDto): string {
    return this.translate.instant('TASKCONFIG.PANEL.2')
      .concat(' ', format(parseISO(taskConfigDto.startDate.toString()), 'yyyy-MM-dd HH:mm'), ' - ', this.getDurationString(moment.duration(taskConfigDto.duration, 'seconds')));
  }
  */

  public getPanelDescription(taskConfigDto: TaskConfigDto): string {
    let formattedDate = 'Invalid Date';
    try {
      if (taskConfigDto.startDate) {
        const date = typeof taskConfigDto.startDate === 'string'
          ? parseISO(taskConfigDto.startDate)
          : new Date(taskConfigDto.startDate);
        formattedDate = format(date, 'yyyy-MM-dd HH:mm');
      }
    } catch (error) {
      console.error('Error parsing date:', error);
    }

    const duration = moment.duration(taskConfigDto.duration, 'seconds');

    return this.translate.instant('TASKCONFIG.PANEL.2')
      .concat(' ', formattedDate, ' - ', this.getDurationString(duration));
  }

  public getPanelTitle(taskConfigDto: TaskConfigDto): string {
    return this.translate.instant('TASKCONFIG.DURATION.REPEAT')
      .concat(' ', this.getRepititionString(taskConfigDto.repeat));
  }

  public async openDialog(repeatType: TaskConfigRepeatTypeDto, taskConfig?: TaskConfigDto): Promise<void> {
    let service: any;
    if (repeatType) {
      switch (repeatType) {
        case TaskConfigRepeatTypeDto.once:
          service = this.onceService;
          break;
        case TaskConfigRepeatTypeDto.daily:
          service = this.dailyService;
          break;
        case TaskConfigRepeatTypeDto.weekly:
          service = this.weeklyService;
          break;
        case TaskConfigRepeatTypeDto.monthly:
          service = this.monthlyService;
          break;
        case TaskConfigRepeatTypeDto.annually:
          service = this.annuallyService;
          break;
      }
    } else {
      service = this.onceService;
    }
    (await service.openDialog(this.data.usergroups, taskConfig)).afterClosed().subscribe((result: TaskConfigDto) => {
      if (result) {
        if (taskConfig) {
          _.pull(this.data.taskConfigs, taskConfig);
        }
        this.data.taskConfigs.push(result);
      }
    });


  }

  private extendRepeatStringWithMonthRepitionString(repeat: TaskConfigRepeatDto): string {
    let s: string = '';
    const month: number[] = repeat.config[TaskConfigRepeatConfigTypeDto.month];
    if (month && month.length > 0) {
      s += this.translate.instant('TASKCONFIG.MONTHS.LABEL').concat(': ');
      _.forEach(month, (no: number) => {
        s += this.translate.instant('TASKCONFIG.MONTHS.' + no.toString()) + ', ';
      });
    }
    return s;
  }

  private extendRepeatStringWithMonthdayRepitionString(repeat: TaskConfigRepeatDto): string {
    let s: string = '';
    const days: number[] = repeat.config[TaskConfigRepeatConfigTypeDto.day];
    if (days && days.length > 0) {
      s += this.translate.instant('TASKCONFIG.RADIOBUTTONS.MONTHDAYS').concat(': ');
      _.forEach(days, (no: number) => {
        s += no < 10 ? '0' + no.toString() : no.toString();
        s += ', ';
      });
    }
    return s;
  }

  private extendRepeatStringWithWeekRepitionString(repeat: TaskConfigRepeatDto): string {
    let s: string = '';
    const week: number[] = repeat.config[TaskConfigRepeatConfigTypeDto.week];
    if (week && week.length > 0) {
      s += this.translate.instant('TASKCONFIG.WEEK.LABEL').concat(': ');
      _.forEach(week, (no: number) => {
        s += this.translate.instant('TASKCONFIG.WEEK.' + no.toString()) + ', ';
      });
    }
    return s;
  }

  private extendRepeatStringWithWeekdayRepitionString(repeat: TaskConfigRepeatDto): string {
    let s: string = '';
    const weekdays: number[] = repeat.config[TaskConfigRepeatConfigTypeDto.weekdays];
    if (weekdays && weekdays.length > 0) {
      s += this.translate.instant('TASKCONFIG.WEEKDAYS.LABEL').concat(': ');
      _.forEach(weekdays, (no: number) => {
        s += this.translate.instant('TASKCONFIG.WEEKDAYS.' + no.toString()) + ', ';
      });
    }
    return s;
  }

  private getDurationString(duration: moment.Duration): string {
    return this.translate.instant('TASKCONFIG.DURATION.LABEL')
      .concat(this.getDurationStringPrecise(duration));
  }

  private getDurationStringPrecise(duration: moment.Duration): string {
    return ''.concat(
      duration.years() > 0 ? duration.years().toString().concat(' ', this.translate.instant('TASKCONFIG.DURATION.YEARS'), ' ') : '',
      duration.months() > 0 ? duration.months().toString().concat(' ', this.translate.instant('TASKCONFIG.DURATION.MONTHS'), ' ') : '',
      duration.days() > 0 ? duration.days().toString().concat(' ', this.translate.instant('TASKCONFIG.DURATION.DAYS'), ' ') : '',
      duration.hours() > 0 ? duration.hours().toString().concat(' ', this.translate.instant('TASKCONFIG.DURATION.HOURS'), ' ') : '',
      duration.minutes() > 0 ? duration.minutes().toString().concat(' ', this.translate.instant('TASKCONFIG.DURATION.MINUTES'), ' ') : '',
    );
  }

  private getExtendedRepititionStrings(repeat: TaskConfigRepeatDto): string[] {
    const strings: string[] = [];
    if (repeat && repeat.type) {
      switch (repeat.type) {
        case TaskConfigRepeatTypeDto.weekly:
          strings.push(this.extendRepeatStringWithWeekdayRepitionString(repeat));
          break;
        case TaskConfigRepeatTypeDto.monthly:
          if (_.get(repeat.config, TaskConfigRepeatConfigTypeDto.weekdays, []).length > 0) {
            strings.push(this.extendRepeatStringWithWeekRepitionString(repeat));
            strings.push(this.extendRepeatStringWithWeekdayRepitionString(repeat));
          }
          if (_.get(repeat.config, TaskConfigRepeatConfigTypeDto.day, []).length > 0) {
            strings.push(this.extendRepeatStringWithMonthdayRepitionString(repeat));
          }
          break;
        case TaskConfigRepeatTypeDto.annually:
          strings.push(this.extendRepeatStringWithMonthRepitionString(repeat));
          if (_.get(repeat.config, TaskConfigRepeatConfigTypeDto.weekdays, []).length > 0) {
            strings.push(this.extendRepeatStringWithWeekRepitionString(repeat));
            strings.push(this.extendRepeatStringWithWeekdayRepitionString(repeat));
          }
          if (_.get(repeat.config, TaskConfigRepeatConfigTypeDto.day, []).length > 0) {
            strings.push(this.extendRepeatStringWithMonthdayRepitionString(repeat));
          }
          break;
      }
    }
    return strings;
  }

  private getRepititionString(repeat: TaskConfigRepeatDto): string {
    let repitionString: string;
    if (repeat && repeat.type) {
      switch (repeat.type) {
        case TaskConfigRepeatTypeDto.once:
          repitionString = this.translate.instant('TASKCONFIG.MENU.ONCE');
          break;
        case TaskConfigRepeatTypeDto.daily:
          repitionString = repeat.value.toString().concat('-', this.translate.instant('TASKCONFIG.MENU.DAILY'));
          break;
        case TaskConfigRepeatTypeDto.weekly:
          repitionString = this.translate.instant('TASKCONFIG.MENU.WEEKLY');
          break;
        case TaskConfigRepeatTypeDto.monthly:
          repitionString = this.translate.instant('TASKCONFIG.MENU.MONTHLY');
          break;
        case TaskConfigRepeatTypeDto.annually:
          repitionString = this.translate.instant('TASKCONFIG.MENU.ANNUALLY');
          break;
      }
    } else {
      repitionString = this.translate.instant('TASKCONFIG.MENU.ONCE');
    }
    return repitionString;
  }

  private parseDate(date: any): Date {
    if (date instanceof Date) {
      return date;
    } else if (typeof date === 'string') {
      return parseISO(date);
    } else if (typeof date === 'number') {
      return new Date(date);
    }
    throw new Error('Invalid date format');
  }
}
