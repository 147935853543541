import { AuthConfig } from 'angular-oauth2-oidc';

function getRandomFloat(min: number, max: number): number {
    return Math.random() * (max - min) + min;
}

export const authCodeFlowConfig: AuthConfig = {
    // Url of the Identity Provider
    // @ts-ignore
    issuer: window.__RUNTIME_CONFIG__.KC_URL,

    // URL of the SPA to redirect the user to after login
    redirectUri: window.location.origin,

    // The SPA's id. The SPA is registerd with this id at the auth-server
    // clientId: 'server.code',
    // @ts-ignore
    clientId: window.__RUNTIME_CONFIG__.KC_CLIENT_ID,

    // Just needed if your auth server demands a secret. In general, this
    // is a sign that the auth server is not configured with SPAs in mind
    // and it might not enforce further best practices vital for security
    // such applications.
    // dummyClientSecret: 'secret',

    responseType: 'code',

    // set the scope for the permissions the client should request
    // The first four are defined by OIDC.
    // Important: Request offline_access to get a refresh token
    // The api scope is a usecase specific one
    scope: 'openid',

    showDebugInformation: false,
    useSilentRefresh: true,
    sessionChecksEnabled: true,
    sessionCheckIntervall: 10 * 1000,
    timeoutFactor: getRandomFloat(0.4, 0.6),
    // startCheckSession: true

};