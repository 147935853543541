import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpErrorResponse
} from '@angular/common/http';
import { from, Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { OAuthService, TokenResponse } from 'angular-oauth2-oidc';
import { PhoenixCommunicationService } from '@phoenix/services/phoenix-communication-service/phoenix-communication.service';
import { PhoenixCommunicationSubject } from '@phoenix/services/phoenix-communication-service/phoenix-communication-subject.enum';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private authService: OAuthService,
        private router: Router,
        private communicationService: PhoenixCommunicationService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const clonedReq = req.clone({
            headers: req.headers.set('Authorization', `Bearer ${localStorage.getItem('access_token')}`)
        });
        return next.handle(clonedReq).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401) {
                    // Handle the 401 error by trying to refresh the token
                    return this.handle401Error(req, next);
                }
                return throwError(() => error);
            })
        );
    }

    private handle401Error(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.authService.hasValidIdToken()) {
            return from(this.authService.refreshToken()).pipe(
                switchMap((tokenResponse: TokenResponse) => {
                    // Assuming TokenResponse has a property like accessToken
                    const newToken = tokenResponse.access_token;

                    // If token refresh is successful, clone the original request and add the new token to it
                    const clonedReq = req.clone({
                        headers: req.headers.set('Authorization', `Bearer ${newToken}`)
                    });
                    return next.handle(clonedReq);
                }),
                catchError((refreshError) => {
                    // If token refresh fails, navigate to login or logout
                    //this.authService.logOut();

                    this.router.navigate(['/login']).then(() => {
                        this.communicationService.emit(PhoenixCommunicationSubject.UserUnauthorized);
                    });
                    return throwError(() => refreshError);
                })
            );
        } else {
            // Token is expired and can't be refreshed, navigate to login or perform logout
            //this.authService.logOut();

            if (!req.url.includes('currentUser')) {
                this.router.navigate(['/login']).then(() => {
                    this.communicationService.emit(PhoenixCommunicationSubject.UserUnauthorized);
                });
            }
            return throwError(() => 'Session expired');
        }

    }
}
