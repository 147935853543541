import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';
import { AssetDto } from '../../../gapicon/asset/dto/asset-dto';
import { PhoenixTenantSelectorDialogData } from './classes/PhoenixTenantSelectorDialogData';
import { PhoenixTenantSelectorDialogComponent } from './phoenix-tenant-selector-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class PhoenixTenantSelectorDialogService {
  private dialogRef: MatDialogRef<PhoenixTenantSelectorDialogComponent>
  public constructor(
    private dialog: MatDialog,
  ) {
  }

  public isDialogOpen(): boolean {
    if (this.dialogRef) {
      const state = this.dialogRef.getState()
      return state == MatDialogState.OPEN;
    }
    return false;
  }

  public closeDialog(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }


  public showTenantSelectorDialog(tenants: AssetDto[], showCancelButton: boolean = false): Observable<AssetDto[]> {
    if (tenants.length <= 1) {
      return of(tenants);
    }

    const dialogRef = this.dialog.open(PhoenixTenantSelectorDialogComponent, {
      closeOnNavigation: false,
      autoFocus: false,
      disableClose: !showCancelButton,
      data: new PhoenixTenantSelectorDialogData({
        tenants: tenants,
        cancelButton: showCancelButton,
        title: 'WIZARDS.TENANT.TITLE',
        subtitle: 'WIZARDS.TENANT.SUBTITLE',
      }),
    });
    this.dialogRef = dialogRef;
    return this.dialogRef.afterClosed();
  }
}
