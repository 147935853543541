<div [formGroup]="form" class="mt-12 mb-12">
  <mat-slide-toggle [formControlName]="phoenixFormControl.key" [(ngModel)]="phoenixFormControl.value">
    {{phoenixFormControl.label | translate }}: {{ phoenixFormControl.value ? ('DIALOG.TOGGLE.ON' | translate) :
    ('DIALOG.TOGGLE.OFF' |
    translate) }}
  </mat-slide-toggle>
  <mat-error *ngIf="form.get(phoenixFormControl.key).invalid">
    <span data-translate="DIALOG.FORM.INVALID" translate></span>
  </mat-error>
  <div *ngIf="phoenixFormControl.options['hint']" class="small-hint">
    {{ phoenixFormControl.options['hint'] | translate }}
  </div>
</div>