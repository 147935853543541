<div class="title-wrapper {{ color }}" mat-dialog-title>
  <div class="h1">{{ title | translate}}</div>
  <div class="mat-body-2 hint-text">{{ subtitle | translate}}</div>
</div>
<div class="content">
  <mat-dialog-content>
    <ng-content></ng-content>
  </mat-dialog-content>
  <mat-dialog-actions *ngIf="buttons">
    <div class="button-container text-center mb-24">
      <mat-divider class="mt-24 mb-24" *ngIf="buttons"></mat-divider>
      <ng-container *ngFor="let button of buttons">
        <button *ngIf="!button.raised" [disabled]="button.disabled() || button.spinner" mat-button class="ml-4"
          [color]="button.color" (click)="button.clickCallback()">
          <mat-spinner [diameter]="20" *ngIf="button.spinner"></mat-spinner>
          {{ button.label | translate }}
        </button>
        <button *ngIf="button.raised" [disabled]="button.disabled() || button.spinner" mat-raised-button class="ml-4"
          [color]="button.color" (click)="button.clickCallback()">
          <mat-spinner [diameter]="20" *ngIf="button.spinner"></mat-spinner>
          {{ button.label | translate }}
        </button>
      </ng-container>
    </div>
  </mat-dialog-actions>
</div>