import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UserGroupDto } from '@phoenix/gapicon/group/dto/user-group-dto';
import { addSeconds, differenceInSeconds, parseISO } from 'date-fns';
import * as _ from 'lodash-es';

import { PhoenixDialogButton } from '../../../../components/phoenix-dialog-new/classes/phoenix-dialog-button';
import { TaskConfigDto } from '../../../../gapicon/dto.module';
import { PhoenixTaskConfigDialog } from '../classes/phoenix-task-config-dialog';

import { PhoenixTaskConfigDialogOnceComponent } from './phoenix-task-config-dialog-once.component';

@Injectable({
  providedIn: 'root',
})
export class PhoenixTaskConfigDialogOnceService {

  public constructor(
    private dialog: MatDialog,
  ) {
  }

  public async openDialog(userGroups: UserGroupDto[], taskConfig?: TaskConfigDto): Promise<MatDialogRef<PhoenixTaskConfigDialogOnceComponent>> {
    const { start, end, assignedUserGroupIds } = this.getValuesForFormGroup(taskConfig);
    const id: string = taskConfig ? taskConfig.id : undefined;
    const formGroup: UntypedFormGroup = new UntypedFormGroup({
      start: new UntypedFormControl(start, [Validators.required]),
      end: new UntypedFormControl(end, [Validators.required]),
      assignedUserGroupIds: new UntypedFormControl(assignedUserGroupIds, [Validators.required]),
    });
    const dialogRef: MatDialogRef<PhoenixTaskConfigDialogOnceComponent> = this.dialog.open(PhoenixTaskConfigDialogOnceComponent, {
      width: '950px',
      autoFocus: false,
      data: <PhoenixTaskConfigDialog>{
        title: 'TASKCONFIG.ONCE.TITLE',
        subtitle: 'TASKCONFIG.ONCE.SUBTITLE',
        buttons: [
          new PhoenixDialogButton({
            label: 'TASKCONFIG.CANCEL',
            click: (): void => dialogRef.close(),
          }),
          new PhoenixDialogButton({
            label: 'TASKCONFIG.ADD',
            click: (): void => dialogRef.close(this.handleDialogResult(id, formGroup.value)),
            color: 'accent',
            raised: true,
            disabled: (): boolean => formGroup.invalid,
          })],
        formGroup: formGroup,
        usergroups: userGroups
      },
    });
    return dialogRef;
  }

  private getValuesForFormGroup(taskConfig: TaskConfigDto): { start, end, assignedUserGroupIds } {
    let start: Date | undefined = undefined;
    let end: Date | undefined = undefined;

    if (_.get(taskConfig, ['startDate'])) {
      try {
        // Parse startDate, handling both string and Date object cases
        start = taskConfig.startDate instanceof Date ? taskConfig.startDate : parseISO(taskConfig.startDate);

        // Calculate end date
        if (typeof taskConfig.duration === 'number') {
          end = addSeconds(start, taskConfig.duration);
        }
      } catch (error) {
        console.error('Error parsing date in getValuesForFormGroup:', error);
      }
    }

    const assignedUserGroupIds: string[] = _.get(taskConfig, ['assignedUserGroupIds'], []) || [];

    return { start, end, assignedUserGroupIds };
  }

  private handleDialogResult(id: string, result: { start: Date, end: Date, assignedUserGroupIds: string[] }): TaskConfigDto {
    return new TaskConfigDto({
      id: id,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone || 'Europe/Berlin',
      startDate: result.start,
      duration: differenceInSeconds(result.end, result.start),
      createdDate: new Date(),
      assignedUserGroupIds: result.assignedUserGroupIds
    });
  }

}
