<div [formGroup]="form">
  <div fxLayout="row" fxLayoutAlign="start center">
    <div fxFlex="80">
      <mat-divider></mat-divider>
    </div>
    <div fxFlex="5">
      <mat-icon class="notranslate w-12 h-12 font-size-12 material-icons rotate135">signal_cellular_null</mat-icon>
    </div>
    <div fxFlex="10">
      <span style="font-size: 12px;">{{ phoenixFormControl.label }}</span>
    </div>
    <div fxFlex="15">
      <input class="threshold-input" type="number" matInput [formControlName]="phoenixFormControl.key"
        [value]="phoenixFormControl.value">
    </div>
  </div>
  <mat-error *ngIf="form.get(phoenixFormControl.key).invalid">
    <span>{{ 'DIALOG.FORM.INVALID' | translate}}</span>
  </mat-error>
</div>