import { SensorConfigDto } from './sensor-config-dto';

export class SensorDto {
  public assetId: string = undefined;
  public currentConfiguration: SensorConfigDto = undefined;
  public wantedConfiguration: SensorConfigDto = undefined;
  public sensorId: string = undefined;
  public type: string = undefined;
  public isOffline: boolean = undefined;
  public isReported: boolean = undefined;

  public constructor(sensor?: Partial<SensorDto>) {
    Object.assign(this, sensor);
  }
}
