import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UserGroupDto } from '@phoenix/gapicon/group/dto/user-group-dto';
import { addSeconds, differenceInSeconds, parseISO } from 'date-fns';
import * as _ from 'lodash-es';

import { PhoenixDialogButton } from '../../../../components/phoenix-dialog-new/classes/phoenix-dialog-button';
import { TaskConfigDto, TaskConfigRepeatConfigTypeDto, TaskConfigRepeatDto, TaskConfigRepeatTypeDto } from '../../../../gapicon/dto.module';
import { PhoenixTaskConfigDialog } from '../classes/phoenix-task-config-dialog';

import { PhoenixTaskConfigDialogWeeklyComponent } from './phoenix-task-config-dialog-weekly.component';


@Injectable({
  providedIn: 'root',
})
export class PhoenixTaskConfigDialogWeeklyService {

  public constructor(
    private dialog: MatDialog,
  ) {
  }

  public async openDialog(userGroups: UserGroupDto[], taskConfig?: TaskConfigDto): Promise<MatDialogRef<PhoenixTaskConfigDialogWeeklyComponent>> {
    const { start, end, turnus, weekdays, assignedUserGroupIds } = this.getValuesForFormGroup(taskConfig);
    const startDatePicker: Date = taskConfig && new Date(taskConfig.startDate) < new Date() ? new Date(taskConfig.startDate) : new Date();
    const id: string = taskConfig ? taskConfig.id : undefined;
    const formGroup: UntypedFormGroup = new UntypedFormGroup({
      start: new UntypedFormControl(start, [Validators.required]),
      end: new UntypedFormControl(end, [Validators.required]),
      turnus: new UntypedFormControl(turnus, [Validators.required]),
      weekdays: new UntypedFormControl(weekdays, [Validators.required]),
      assignedUserGroupIds: new UntypedFormControl(assignedUserGroupIds, [Validators.required]),
    });
    const dialogRef: MatDialogRef<PhoenixTaskConfigDialogWeeklyComponent> = this.dialog.open(PhoenixTaskConfigDialogWeeklyComponent, {
      width: '950px',
      autoFocus: false,
      data: <PhoenixTaskConfigDialog>{
        title: 'TASKCONFIG.WEEKLY.TITLE',
        subtitle: 'TASKCONFIG.WEEKLY.SUBTITLE',
        buttons: [
          new PhoenixDialogButton({
            label: 'TASKCONFIG.CANCEL',
            click: (): void => dialogRef.close(),
          }),
          new PhoenixDialogButton({
            label: 'TASKCONFIG.ADD',
            click: (): void => dialogRef.close(this.handleDialogResult(id, formGroup.value)),
            color: 'accent',
            raised: true,
            disabled: (): boolean => formGroup.invalid,
          })],
        formGroup: formGroup,
        startDatePicker: startDatePicker,
        usergroups: userGroups
      },
    });
    return dialogRef;

  }

  /*
  private getValuesForFormGroup(taskConfig: TaskConfigDto): { start, end, turnus, weekdays, assignedUserGroupIds } {
    const start: Date = _.get(taskConfig, ['startDate']) ? new Date(taskConfig.startDate) : undefined;
    const end: Date = _.get(taskConfig, ['startDate']) ? addSeconds(taskConfig.startDate, taskConfig.duration) : undefined;
    const turnus: number = _.get(taskConfig, ['repeat', 'value'], 1);
    const weekdays: number[] = _.get(taskConfig, ['repeat', 'config', TaskConfigRepeatConfigTypeDto.weekdays], []);
    const assignedUserGroupIds: string[] = _.get(taskConfig, ['assignedUserGroupIds'], []);
    return { start, end, turnus, weekdays, assignedUserGroupIds };
  }
  */

  private getValuesForFormGroup(taskConfig: TaskConfigDto): { start, end, turnus, weekdays, assignedUserGroupIds } {
    let start: Date | undefined = undefined;
    let end: Date | undefined = undefined;

    if (_.get(taskConfig, ['startDate'])) {
      try {
        // Parse startDate, handling both string and Date object cases
        start = taskConfig.startDate instanceof Date ? taskConfig.startDate : parseISO(taskConfig.startDate);

        // Calculate end date
        if (typeof taskConfig.duration === 'number') {
          end = addSeconds(start, taskConfig.duration);
        }
      } catch (error) {
        console.error('Error parsing date in getValuesForFormGroup:', error);
      }
    }

    const turnus: number = _.get(taskConfig, ['repeat', 'value'], 1);
    const weekdays: number[] = _.get(taskConfig, ['repeat', 'config', TaskConfigRepeatConfigTypeDto.weekdays], []);
    const assignedUserGroupIds: string[] = _.get(taskConfig, ['assignedUserGroupIds'], []) || [];

    return { start, end, turnus, weekdays, assignedUserGroupIds };
  }


  private handleDialogResult(id: string, result: { start: Date, end: Date, turnus: number, weekdays: number[], assignedUserGroupIds: string[] }): TaskConfigDto {
    const config: { [key: string]: number[] } = {};
    config[TaskConfigRepeatConfigTypeDto.weekdays] = result.weekdays;
    const repeat: TaskConfigRepeatDto = new TaskConfigRepeatDto({
      config: config,
      type: TaskConfigRepeatTypeDto.weekly,
      value: result.turnus,
    });

    return new TaskConfigDto({
      id: id,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone || 'Europe/Berlin',
      startDate: result.start,
      repeat: repeat,
      duration: differenceInSeconds(result.end, result.start),
      createdDate: new Date(),
      assignedUserGroupIds: result.assignedUserGroupIds
    });
  }
}
