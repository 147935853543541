<phoenix-dialog-new [buttons]="data.buttons" [subtitle]="data.subtitle" [title]="data.title">
  <div class="text-semibold mt-20 text-truncate" style="margin-left: 18px;">{{'TASKCONFIG.ONCE.DESCRIPTION' |
    translate}}</div>
  <form [formGroup]="data.formGroup">
    <div fxLayout="column">
      <mat-form-field>
        <label class="mr-8" matPrefix>&nbsp;&nbsp;&nbsp;&nbsp;{{'TASKCONFIG.ONCE.START.PREFIX' | translate}}</label>
        <!--<mat-placeholder>{{'TASKCONFIG.ONCE.START.PLACEHOLDER' | translate}}</mat-placeholder>-->
        <mat-datetimepicker-toggle [for]="datetimePickerStart" matSuffix></mat-datetimepicker-toggle>
        <mat-datetimepicker #datetimePickerStart [startAt]="today" timeInterval="5" type="datetime">
        </mat-datetimepicker>
        <input [matDatetimepicker]="datetimePickerStart" [min]="today" class="text-center" formControlName="start"
          matInput readonly placeholder="{{'TASKCONFIG.ONCE.START.PLACEHOLDER' | translate}}">
        <label class="ml-8" matSuffix>{{'TASKCONFIG.ONCE.START.SUFFIX' | translate}}</label>
      </mat-form-field>
      <mat-form-field>
        <label class="mr-8" matPrefix>&nbsp;&nbsp;&nbsp;&nbsp;{{'TASKCONFIG.ONCE.END.PREFIX' | translate}}</label>
        <!--<mat-placeholder>{{'TASKCONFIG.ONCE.END.PLACEHOLDER' | translate}}</mat-placeholder>-->
        <mat-datetimepicker-toggle [for]="datetimePickerEnd" matSuffix></mat-datetimepicker-toggle>
        <mat-datetimepicker #datetimePickerEnd timeInterval="5" type="datetime"></mat-datetimepicker>
        <input [matDatetimepicker]="datetimePickerEnd" [min]="getStartDate()" class="text-center" formControlName="end"
          matInput readonly placeholder="{{'TASKCONFIG.ONCE.END.PLACEHOLDER' | translate}}">
        <label class="ml-8" matSuffix>{{'TASKCONFIG.ONCE.END.SUFFIX' | translate}}</label>
      </mat-form-field>
      <mat-form-field>
        <mat-select [placeholder]="'TASKCONFIG.ASSIGNGROUPS.ADDREMOVE' | translate" class="text-left"
          formControlName="assignedUserGroupIds" multiple>
          <mat-option *ngFor="let userGroup of userGroups" [value]="userGroup.id">{{userGroup.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </form>
</phoenix-dialog-new>