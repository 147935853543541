/* tslint:disable */
import { Component, HostBinding, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseConfig } from '@fuse/types';

import { PhoenixCommunicationSubject } from '@phoenix/services/phoenix-communication-service/phoenix-communication-subject.enum';
import { PhoenixCommunicationService } from '@phoenix/services/phoenix-communication-service/phoenix-communication.service';
import { combineLatest, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { PermissionService } from '../../../guards/permission.service';

@Component({
  selector: 'content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class ContentComponent implements OnDestroy {
  public fuseSettings: FuseConfig;
  public isLoggedIn: boolean = false;
  public onConfigChanged: Subscription;
  @HostBinding('@routerTransitionDown')
  public routeAnimationDown: boolean = false;
  @HostBinding('@routerTransitionFade')
  public routeAnimationFade: boolean = false;
  @HostBinding('@routerTransitionLeft')
  public routeAnimationLeft: boolean = false;
  @HostBinding('@routerTransitionRight')
  public routeAnimationRight: boolean = false;
  @HostBinding('@routerTransitionUp')
  public routeAnimationUp: boolean = false;
  public showLoadingBar: boolean;
  private styleClasses: { [key: string]: string } = {
    current: 'backgroundImage',
    backgroundImage: 'backgroundImage',
    noBackgroundImage: 'noBackgroundImage',
  };

  public constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private fuseConfig: FuseConfigService,
    private permissionService: PermissionService,
    private communicationService: PhoenixCommunicationService,
  ) {

    combineLatest(
      this.communicationService.getObservable(PhoenixCommunicationSubject.UserLogin),
      this.router.events
    ).subscribe(([loggedIn, routingEvent]: [boolean, NavigationEnd]) => {
      if (loggedIn && routingEvent instanceof NavigationEnd) {
        this.isLoggedIn = loggedIn;
      }
    });

    this.communicationService.getObservable(PhoenixCommunicationSubject.UserLogout).subscribe((success: { value: boolean }) => {
      this.isLoggedIn = success.value;
    });

    this.communicationService.getObservable(PhoenixCommunicationSubject.UserUnauthorized).subscribe((success: { value: boolean }) => {

      this.styleClasses.current = this.styleClasses.backgroundImage;
      this.fuseConfig.setConfig({
        layout: {
          navbar: { hidden: false },
          toolbar: { hidden: false },
          footer: { hidden: true },
        },
      });

    });

    this.isLoggedIn = localStorage.getItem("access_token") && localStorage.getItem("selectedTenants") ? true : false;


    this.router.events.pipe(
      filter((event: Event | RouterEvent) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
    ).subscribe((event: ActivatedRoute) => {
      this.isLoggedIn = localStorage.getItem("access_token") && localStorage.getItem("selectedTenants") ? true : false;

      switch (this.fuseSettings.routerAnimation) {
        case 'fadeIn':
          this.routeAnimationFade = !this.routeAnimationFade;
          break;
        case 'slideUp':
          this.routeAnimationUp = !this.routeAnimationUp;
          break;
        case 'slideDown':
          this.routeAnimationDown = !this.routeAnimationDown;
          break;
        case 'slideRight':
          this.routeAnimationRight = !this.routeAnimationRight;
          break;
        case 'slideLeft':
          this.routeAnimationLeft = !this.routeAnimationLeft;
          break;
      }

      if (permissionService.userIsNotAuthenticated() || !localStorage.getItem("availableTenants")) {
        this.styleClasses.current = this.styleClasses.backgroundImage;
        this.fuseConfig.setConfig({
          layout: {
            navbar: { hidden: true },
            toolbar: { hidden: true },
            footer: { hidden: true },
          },
        });
      } else {
        this.styleClasses.current = this.styleClasses.backgroundImage;
        this.fuseConfig.setConfig({
          layout: {
            navbar: { hidden: false },
            toolbar: { hidden: false },
            footer: { hidden: true },
          },
        });
      }
    });

    this.onConfigChanged =
      this.fuseConfig.getConfig()
        .subscribe(
          (newSettings: FuseConfig) => {
            this.fuseSettings = newSettings;
          },
        );
  }

  public getStyleClasses(): string {
    return this.styleClasses.current;
  }

  public ngOnDestroy(): void {
    this.onConfigChanged.unsubscribe();
  }
}
