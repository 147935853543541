import moment, { Moment } from 'moment';
import { TimeWindowConfigDayDto } from "../dto/time-window-config-day-dto";
import { TimeWindowConfigDto } from "../dto/time-window-config-dto";
import { TimeWindowConfigIntervallDto } from '../dto/time-window-config-intervall-dto';

export class TimeWindowHelper {

  public static convertTimeWindowConfigDtoFromUTCtoLocal(timeWindowConfigDto: TimeWindowConfigDto): TimeWindowConfigDto {
    let intervals: Interval[] = [];

    intervals = this.createLocalIntervalsFromUTCConfig(timeWindowConfigDto);
    intervals = this.correctWeekOverlapping(intervals, false);
    intervals = this.sortIntervals(intervals);
    intervals = this.joinContinuousIntervalsInDay(intervals);
    let convertedConfig = this.createConfigFromIntervals(intervals);
    convertedConfig.dstOffset = timeWindowConfigDto.dstOffset;
    return convertedConfig;
  }

  public static convertTimeWindowConfigDtoFromLocaltoUTC(timeWindowConfigDto: TimeWindowConfigDto): TimeWindowConfigDto {
    let intervals: Interval[] = [];

    intervals = this.createUTCIntervalsFromLocalConfig(timeWindowConfigDto);
    intervals = this.correctWeekOverlapping(intervals, true);
    intervals = this.sortIntervals(intervals);
    intervals = this.joinContinuousIntervalsInDay(intervals);
    let convertedConfig = this.createConfigFromIntervals(intervals);
    convertedConfig.dstOffset = timeWindowConfigDto.dstOffset;
    return convertedConfig;
  }

  private static createLocalIntervalsFromUTCConfig(timeWindowConfigDto: TimeWindowConfigDto): Interval[] {
    let intervals: Interval[] = [];
    const dstOffset = timeWindowConfigDto.dstOffset;
    if (timeWindowConfigDto) {
      if (timeWindowConfigDto.monday) {
        let dayIntervals = this.convertUTCConfigDayToLocalIntervals(timeWindowConfigDto.monday, 1, dstOffset);
        intervals = intervals.concat(dayIntervals);
      }
      if (timeWindowConfigDto.tuesday) {
        let dayIntervals = this.convertUTCConfigDayToLocalIntervals(timeWindowConfigDto.tuesday, 2, dstOffset);
        intervals = intervals.concat(dayIntervals);
      }
      if (timeWindowConfigDto.wednesday) {
        let dayIntervals = this.convertUTCConfigDayToLocalIntervals(timeWindowConfigDto.wednesday, 3, dstOffset);
        intervals = intervals.concat(dayIntervals);
      }
      if (timeWindowConfigDto.thursday) {
        let dayIntervals = this.convertUTCConfigDayToLocalIntervals(timeWindowConfigDto.thursday, 4, dstOffset);
        intervals = intervals.concat(dayIntervals);
      }
      if (timeWindowConfigDto.friday) {
        let dayIntervals = this.convertUTCConfigDayToLocalIntervals(timeWindowConfigDto.friday, 5, dstOffset);
        intervals = intervals.concat(dayIntervals);
      }
      if (timeWindowConfigDto.saturday) {
        let dayIntervals = this.convertUTCConfigDayToLocalIntervals(timeWindowConfigDto.saturday, 6, dstOffset);
        intervals = intervals.concat(dayIntervals);
      }
      if (timeWindowConfigDto.sunday) {
        let dayIntervals = this.convertUTCConfigDayToLocalIntervals(timeWindowConfigDto.sunday, 7, dstOffset);
        intervals = intervals.concat(dayIntervals);
      }
    }
    return intervals;
  }

  private static convertUTCConfigDayToLocalIntervals(timeWindowConfigDayDto: TimeWindowConfigDayDto, weekday: number, dstOffset: number): Interval[] {
    const now = moment.utc();
    const timezone = moment().utcOffset(); //summer: 120, winter: 60

    let offset = 0;
    if (dstOffset == 1 && timezone == 60) {
      offset = 1;
    } else if (dstOffset == 0 && timezone == 120) {
      offset = -1;
    }

    let intervals: Interval[] = []
    if (!timeWindowConfigDayDto.enable) {
      let interval = new Interval();
      interval.startDate = moment(now).isoWeekday(weekday).utcOffset(timezone).utc().startOf('day').local();
      interval.endDate = moment(interval.startDate).add(1, 'day');
      intervals = intervals.concat(this.splitIntervalIfOverlapping(interval));
    } else if (this.isFullDayBlock(timeWindowConfigDayDto)) {
      let interval = new Interval();
      interval.startDate = moment(now).isoWeekday(weekday).utcOffset(timezone).utc().startOf('day').local();
      interval.endDate = moment(now).isoWeekday(weekday).utcOffset(timezone).utc().startOf('day').local();
      intervals = intervals.concat(this.splitIntervalIfOverlapping(interval));
    } else {
      for (let i = 0; i < timeWindowConfigDayDto.intervalls.length; i++) {
        let interval = new Interval();
        let startTime = timeWindowConfigDayDto.intervalls[i].startTime.split(':');
        let endTime = timeWindowConfigDayDto.intervalls[i].endTime.split(':');
        const startHour = startTime[0] == '0' ? startTime[0] : parseInt(startTime[0]) + offset;
        const endHour = endTime[0] == '0' ? endTime[0] : parseInt(endTime[0]) + offset;

        interval.startDate = moment(now).isoWeekday(weekday).utcOffset(timezone).utc().hours(+/*startTime[0]*/startHour).minutes(+startTime[1]).seconds(0)/*.add(offset, 'hour')*/.local();

        interval.endDate = moment(now).isoWeekday(weekday).utcOffset(timezone).utc().hours(+/*endTime[0]*/endHour).minutes(+endTime[1]).seconds(0)/*.add(0, 'hour')*/.local();
        if (timeWindowConfigDayDto.intervalls[i].endTime == '0:00') {
          interval.endDate.add(1, 'day');
        }
        intervals = intervals.concat(this.splitIntervalIfOverlapping(interval));
      }
    }
    return intervals;
  }

  private static createUTCIntervalsFromLocalConfig(timeWindowConfigDto: TimeWindowConfigDto): Interval[] {
    let intervals: Interval[] = [];
    if (timeWindowConfigDto) {
      if (timeWindowConfigDto.monday) {
        let dayIntervals = this.convertLocalConfigDayToUTCIntervals(timeWindowConfigDto.monday, 1);
        intervals = intervals.concat(dayIntervals);
      }
      if (timeWindowConfigDto.tuesday) {
        let dayIntervals = this.convertLocalConfigDayToUTCIntervals(timeWindowConfigDto.tuesday, 2);
        intervals = intervals.concat(dayIntervals);
      }
      if (timeWindowConfigDto.wednesday) {
        let dayIntervals = this.convertLocalConfigDayToUTCIntervals(timeWindowConfigDto.wednesday, 3);
        intervals = intervals.concat(dayIntervals);
      }
      if (timeWindowConfigDto.thursday) {
        let dayIntervals = this.convertLocalConfigDayToUTCIntervals(timeWindowConfigDto.thursday, 4);
        intervals = intervals.concat(dayIntervals);
      }
      if (timeWindowConfigDto.friday) {
        let dayIntervals = this.convertLocalConfigDayToUTCIntervals(timeWindowConfigDto.friday, 5);
        intervals = intervals.concat(dayIntervals);
      }
      if (timeWindowConfigDto.saturday) {
        let dayIntervals = this.convertLocalConfigDayToUTCIntervals(timeWindowConfigDto.saturday, 6);
        intervals = intervals.concat(dayIntervals);
      }
      if (timeWindowConfigDto.sunday) {
        let dayIntervals = this.convertLocalConfigDayToUTCIntervals(timeWindowConfigDto.sunday, 7);
        intervals = intervals.concat(dayIntervals);
      }
    }
    return intervals;
  }

  private static convertLocalConfigDayToUTCIntervals(timeWindowConfigDayDto: TimeWindowConfigDayDto, weekday: number): Interval[] {
    console.log("convertLocalConfigDayToUTCIntervals called");
    const now = moment();
    const timezone = now.utcOffset();
    let intervals: Interval[] = []
    if (!timeWindowConfigDayDto.enable) {
      let interval = new Interval();
      interval.startDate = moment(now).isoWeekday(weekday).utcOffset(timezone).startOf('day').utc();
      interval.endDate = moment(interval.startDate).add(1, 'day');
      intervals = intervals.concat(this.splitIntervalIfOverlapping(interval));
    } else if (this.isFullDayBlock(timeWindowConfigDayDto)) {
      let interval = new Interval();
      interval.startDate = moment(now).isoWeekday(weekday).utcOffset(timezone).startOf('day').utc();
      interval.endDate = moment(now).isoWeekday(weekday).utcOffset(timezone).startOf('day').utc();
      intervals = intervals.concat(this.splitIntervalIfOverlapping(interval));
    } else {
      for (let i = 0; i < timeWindowConfigDayDto.intervalls.length; i++) {
        let interval = new Interval();
        let startTime = timeWindowConfigDayDto.intervalls[i].startTime.split(':');
        let endTime = timeWindowConfigDayDto.intervalls[i].endTime.split(':');
        interval.startDate = moment(now).isoWeekday(weekday).utcOffset(timezone).hours(+startTime[0]).minutes(+startTime[1]).seconds(0).utc();
        interval.endDate = moment(now).isoWeekday(weekday).utcOffset(timezone).hours(+endTime[0]).minutes(+endTime[1]).seconds(0).utc();
        if (timeWindowConfigDayDto.intervalls[i].endTime == '0:00') {
          interval.endDate.add(1, 'day');
        }
        intervals = intervals.concat(this.splitIntervalIfOverlapping(interval));
      }
    }
    return intervals;
  }

  private static splitIntervalIfOverlapping(interval: Interval): Interval[] {
    let intervals: Interval[] = [];
    if (interval && interval.startDate.isoWeekday() != interval.endDate.isoWeekday()
      && !(interval.endDate.hours() == 0 && interval.endDate.minutes() == 0 && interval.endDate.seconds() == 0)) {
      intervals[0] = new Interval();
      intervals[0].startDate = moment(interval.startDate);
      intervals[0].endDate = moment(interval.startDate).add(1, 'day').startOf('day');
      intervals[1] = new Interval();
      intervals[1].startDate = moment(interval.startDate).add(1, 'day').startOf('day');
      intervals[1].endDate = moment(interval.endDate);
    } else {
      intervals.push(interval);
    }
    return intervals;
  }

  private static isFullDayBlock(timeWindowConfigDayDto: TimeWindowConfigDayDto): boolean {
    if (!timeWindowConfigDayDto.intervalls || timeWindowConfigDayDto.intervalls &&
      (timeWindowConfigDayDto.intervalls.length == 0 || timeWindowConfigDayDto.intervalls.length == 1
        && timeWindowConfigDayDto.intervalls[0].startTime == '0:00' && timeWindowConfigDayDto.intervalls[0].endTime == '0:00')) {
      return true
    } else {
      return false;
    }
  }

  private static correctWeekOverlapping(intervals: Interval[], utc: boolean): Interval[] {
    if (intervals && intervals.length > 0) {
      const monday = utc ? moment.utc().isoWeekday(1).startOf('day') : moment().isoWeekday(1).startOf('day');
      const sunday = utc ? moment.utc().isoWeekday(7).startOf('day') : moment().isoWeekday(7).startOf('day');
      for (let i = 0; i < intervals.length; i++) {
        if (intervals[i].startDate.isBefore(monday, 'day')) {
          intervals[i].startDate.add(7, 'day');
          intervals[i].endDate.add(7, 'day');
        } else if (intervals[i].startDate.isAfter(sunday, 'day')) {
          intervals[i].startDate.subtract(7, 'day');
          intervals[i].endDate.subtract(7, 'day');
        }
      }
    }
    return intervals;
  }

  private static sortIntervals(intervals: Interval[]): Interval[] {
    return intervals.sort((a, b) => {
      if (a.startDate.isBefore(b.startDate)) {
        return -1;
      } else if (a.startDate.isSame(b.startDate)) {
        return 0;
      } else {
        return 1;
      }
    });
  }

  private static joinContinuousIntervalsInDay(intervals: Interval[]): Interval[] {
    let joinedIntervals: Interval[] = [];
    if (intervals && intervals.length > 1) {

      let j = 0;
      joinedIntervals[j] = intervals[j];
      for (let i = 1; i < intervals.length; i++) {
        if (joinedIntervals[j].startDate.isSame(intervals[i].startDate, 'day')
          && joinedIntervals[j].endDate.isSame(intervals[i].startDate, 'minute')) {
          joinedIntervals[j].endDate = intervals[i].endDate;
        } else {
          j++;
          joinedIntervals[j] = intervals[i];
        }
      }
    }
    return joinedIntervals;
  }

  private static createConfigFromIntervals(intervals: Interval[]): TimeWindowConfigDto {
    let timeWindowConfigDto = new TimeWindowConfigDto();
    let dayIntervals: Interval[][] = [];
    if (intervals && intervals.length > 0) {
      for (let i = 0; i < intervals.length; i++) {
        if (!dayIntervals[intervals[i].startDate.isoWeekday() - 1]) {
          dayIntervals[intervals[i].startDate.isoWeekday() - 1] = [];
        }
        dayIntervals[intervals[i].startDate.isoWeekday() - 1].push(intervals[i]);
      }
    }

    timeWindowConfigDto.monday = this.createConfigDayDtoFromDayIntervals(dayIntervals[0]);
    timeWindowConfigDto.tuesday = this.createConfigDayDtoFromDayIntervals(dayIntervals[1]);
    timeWindowConfigDto.wednesday = this.createConfigDayDtoFromDayIntervals(dayIntervals[2]);
    timeWindowConfigDto.thursday = this.createConfigDayDtoFromDayIntervals(dayIntervals[3]);
    timeWindowConfigDto.friday = this.createConfigDayDtoFromDayIntervals(dayIntervals[4]);
    timeWindowConfigDto.saturday = this.createConfigDayDtoFromDayIntervals(dayIntervals[5]);
    timeWindowConfigDto.sunday = this.createConfigDayDtoFromDayIntervals(dayIntervals[6]);
    return timeWindowConfigDto;
  }

  private static createConfigDayDtoFromDayIntervals(intervals: Interval[]): TimeWindowConfigDayDto {
    let timeWindowConfigDay = new TimeWindowConfigDayDto();
    if (intervals && intervals.length > 0) {
      let filteredIntervals: Interval[] = []
      for (let i = 0; i < intervals.length; i++) {
        if (intervals[i] && !intervals[i].startDate.isSame(intervals[i].endDate, 'minute')) {
          filteredIntervals.push(intervals[i]);
        }
      }
      if (filteredIntervals.length == 0) {
        timeWindowConfigDay.enable = true;
        timeWindowConfigDay.intervalls = null;
      } else if (filteredIntervals.length == 1 && this.isFullDayInterval(filteredIntervals[0])) {
        timeWindowConfigDay.enable = false;
        timeWindowConfigDay.intervalls = null;
      } else if (filteredIntervals.length == 1 && filteredIntervals[0].startDate.isSame(filteredIntervals[0].endDate, 'minute')) {
        timeWindowConfigDay.enable = true;
        timeWindowConfigDay.intervalls = null;
      } else {
        timeWindowConfigDay.enable = true;
        timeWindowConfigDay.intervalls = [];
        for (let i = 0; i < filteredIntervals.length; i++) {
          timeWindowConfigDay.intervalls.push(this.createConfigIntervalFromInterval(filteredIntervals[i]));
        }
      }
    } else {
      timeWindowConfigDay.enable = true;
      timeWindowConfigDay.intervalls = null;
    }
    return timeWindowConfigDay;
  }

  private static isFullDayInterval(interval: Interval): boolean {
    if (interval && interval.startDate.hours() == 0 && interval.startDate.minutes() == 0
      && interval.startDate.seconds() == 0 && moment(interval.startDate).add(1, 'day').isSame(interval.endDate, 'minute')) {
      return true;
    } else {
      return false;
    }
  }

  private static createConfigIntervalFromInterval(interval: Interval): TimeWindowConfigIntervallDto {
    let timeWindowConfigIntervalDto = new TimeWindowConfigIntervallDto();
    if (interval && interval.startDate && interval.endDate) {
      timeWindowConfigIntervalDto.startTime = interval.startDate.format('H:mm');
      timeWindowConfigIntervalDto.endTime = interval.endDate.format('H:mm');
    }
    return timeWindowConfigIntervalDto;
  }

}

export class Interval {
  startDate: Moment;
  endDate: Moment;
}