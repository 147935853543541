import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { PhoenixStatusColor } from '@phoenix/enums/phoenix-status-color.enum';
import * as _ from 'lodash-es';

import { PhoenixDynamicFormControl } from '../../classes/phoenix-dynamic-form-control';

@Component({
  selector: 'phoenix-dynamic-icon-button',
  templateUrl: './phoenix-dynamic-icon-button.component.html',
  styleUrls: ['./phoenix-dynamic-icon-button.component.scss'],
})
export class PhoenixDynamicIconButtonComponent implements OnInit {
  public defaultIconColor: string = PhoenixStatusColor.DEFAULT;
  @Input()
  public form: UntypedFormGroup;
  @Input()
  public phoenixFormControl: PhoenixDynamicFormControl<boolean>;
  public showSpinner: boolean;

  public buttonCallback(): void {
    if (!this.form.get('buttonClicked')) {
      this.form.addControl('buttonClicked', new UntypedFormControl());
    }
    this.showSpinner = true;
    this.form.get('buttonClicked').setValue(this.phoenixFormControl.key);
  }

  public async callbackFromOptions(): Promise<void> {
    await this.phoenixFormControl.options['callback'](this.form);
  }

  public ngOnInit(): void {
  }

  public getIconStyle(): Object {
    let style = {
      'color': this.phoenixFormControl.options['iconColor'] ? this.phoenixFormControl.options['iconColor'] : this.defaultIconColor,
      'font-size.px': 24,
      'margin-top': '15px'
    }
    return style;
  }
}
