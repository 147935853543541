import { addSeconds } from 'date-fns';

import { UserInfoDto } from '../../user/dto/user-info-dto';

import { UserMapperService } from '../../user/mapper/user-mapper.service';
import { TokenRequestResponse } from '../api/token-request-response';


export class AuthInfoDto {
  // tslint:disable-next-line
  public access_token: string;
  // tslint:disable-next-line
  public refresh_token: string;
  public userInfo: UserInfoDto;
  public expires: Date = undefined;
  public roles: { [key: string]: string[] };
  public currentTenants: string[];
  public groupAssetPairs: string[] = [];
  public currentTenant: string;

  public constructor(
    private userMapper: UserMapperService,
    authInfoDto?: Partial<AuthInfoDto>) {
    Object.assign(this, authInfoDto);
  }

  public getExpirationDateFromAccessToken(token: string): Date {
    // exp - the number of seconds (not milliseconds) since Epoch
    const expirationDateTime: number = JSON.parse(atob(token.split('.')[1])).exp;
    return new Date(expirationDateTime * 1000);
  }

  public applyTokenRequestResponse(trr: TokenRequestResponse): void {
    this.access_token = trr.access_token;
    this.refresh_token = trr.refresh_token;
    this.expires = trr.autoLogin ? this.getExpirationDateFromAccessToken(trr.access_token) : addSeconds(new Date(), trr.expires_in);
    this.roles = trr.roles;
    this.userInfo = this.userMapper.userInfoToUserInfoDto(trr.userInfo);
  }
}
