import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRouteSnapshot, Route, Router } from '@angular/router';
import { AuthApiService } from '@phoenix/gapicon/auth/services/auth-api.service';
import { UserApiService } from '@phoenix/gapicon/user/services/user-api.service';
import { PhoenixCommunicationSubject } from '@phoenix/services/phoenix-communication-service/phoenix-communication-subject.enum';
import { PhoenixCommunicationService } from '@phoenix/services/phoenix-communication-service/phoenix-communication.service';
import * as _ from 'lodash-es';
import { Observable, of, lastValueFrom } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AssetApiService } from '../../@phoenix/gapicon/asset/services/asset-api.service';
import {
  AssetDto,
  UserDto
} from '../../@phoenix/gapicon/dto.module';
import { UserGroupDto } from '../../@phoenix/gapicon/group/dto/user-group-dto';
import { Roles } from '../../@phoenix/gapicon/role/api/roles.enum';
import { PhoenixTenantSelectorDialogService } from '../../@phoenix/templates/phoenix-dialog/phoenix-tenantselector-dialog/phoenix-tenant-selector-dialog.service';
import { AppInitStorageService } from '../services/app-init-storage.service';

import { Permission, permissionRole } from './permission';
import { PhoenixAssetBranchselectorDialogService } from '../../@phoenix/templates/phoenix-dialog/phoenix-asset-branchselector-dialog/phoenix-asset-branchselector-dialog.service';
import { TenantApiService } from "../../@phoenix/gapicon/tenant/services/tenant-api.service";
import { OAuthService } from 'angular-oauth2-oidc';
import to from 'await-to-js';

@Injectable()
export class PermissionService {

  public rolesWithTenantNames: { [key: string]: string[] };
  private _availableTenants: AssetDto[];
  private _availableAssetRootBranches: AssetDto[];
  private _tenants: AssetDto[];
  private _assetBranches: AssetDto[];
  private _user: UserDto;
  private tokenExpirationTimer = null;
  // autoLogout after 10h from last user action(period of time in seconds)
  private readonly autoLogoutAfter: number = 60 * 60 * 10;
  private _defaultUrl = "/dashboard"
  private _startUrl = ""

  public constructor(
    private authApi: AuthApiService,
    private userApi: UserApiService,
    private assetApi: AssetApiService,
    private router: Router,
    private communicationService: PhoenixCommunicationService,
    private tenantSelectorDialogService: PhoenixTenantSelectorDialogService,
    private assetBranchSelectorDialogService: PhoenixAssetBranchselectorDialogService,
    private appInitStorageService: AppInitStorageService,
    private tenantApi: TenantApiService,
    private location: Location,
    private oauthService: OAuthService,
  ) {
    this._startUrl = window.location.href.replace(window.location.origin, "").replace("/#", "");

    this.communicationService.getObservable(PhoenixCommunicationSubject.UserUpdated)
      .subscribe((userDto: UserDto) => this.user = userDto);

    this.oauthService.events.subscribe(e => {
      //console.log('oauthService event', e.type)
      if (e.type === 'logout') {
        this.logout()
        window.location.reload();
      }
    });
  }

  public get availableTenants(): AssetDto[] {
    return this._availableTenants;
  }

  public get availableAssetRootBranches(): AssetDto[] {
    return this._availableAssetRootBranches;
  }

  public get tenants(): AssetDto[] {
    return this._tenants;
  }

  public get assetBranches(): AssetDto[] {
    return this._assetBranches;
  }

  public set assetBranches(value: AssetDto[]) {
    // save current selected assetBranch to local storage;
    this._assetBranches = value;
    localStorage.setItem('selectedAssetBranches', JSON.stringify(value.map((asset: AssetDto) => asset.id)));
    //this.communicationService.emit(PhoenixCommunicationSubject.AssetBranchChanged, this._assetBranches);
    //this.communicationService.emit(PhoenixCommunicationSubject.AssetBranchChanged, this.tenants);
  }


  public set tenants(value: AssetDto[]) {
    this._tenants = value;
    // save current selected tenant to local storage;
    if (value && value.length == 1) {
      localStorage.setItem('selectedTenants', JSON.stringify(value.map((tenant: AssetDto) => tenant.id)));
    }
    this.authApi.setCurrentTenants(value.map((tenant: AssetDto) => tenant.id));
    // this.communicationService.emit(PhoenixCommunicationSubject.TenantChanged, this.tenants);
  }

  public get user(): UserDto {
    return this._user;
  }

  public set user(value: UserDto) {
    this._user = value;
    this.communicationService.emit(PhoenixCommunicationSubject.UserChanged, this.user);
  }

  /*
  public getAvailableTenantIds(): string[] {

    console.log('claims groups:', this.oauthService.getIdentityClaims()['groups']);

    if (this.oauthService.getIdentityClaims() && this.oauthService.getIdentityClaims()['groups']) {
      return this.authApi.getTenantsIdsFromGroupRole(this.oauthService.getIdentityClaims()['groups'].filter(g => g.split('/').length == 5));
    }
    return [];
  }
  */

  public getAvailableTenantIds(): string[] {
    if (this.user) {
      return Array.from(new Set(
        this.authApi.getTenantsIdsFromGroupRole(this.user.roles.admin)
          .concat(this.authApi.getTenantsIdsFromGroupRole(this.user.roles.user)),
      ));
    }
    return [];
  }


  public getAvailableTenants(): Observable<AssetDto[]> {
    const tenantIds: string[] = this.getAvailableTenantIds();
    if (tenantIds.length > 0) {
      this.authApi.setCurrentTenants(tenantIds);
      return this.tenantApi.getAllTenantsForUserWithId(this.user.id).pipe(
        tap(() => this.authApi.setCurrentTenants(tenantIds)),
      );
    } else {
      return of([]);
    }
  }

  public async refreshBranchesForSelectedTenant(): Promise<void> {
    this._availableAssetRootBranches = await this.getAvailableAssetBranchesForSelectedTenant().toPromise();
    const newAssetBranch: AssetDto[] = await this.assetBranchSelectorDialogService.showAssetBranchSelectorDialog(this._availableAssetRootBranches).toPromise();
    if (Array.isArray(newAssetBranch) && newAssetBranch.length) {
      this.assetBranches = newAssetBranch;
      this.updateGroupAssetPairs();
      this.communicationService.emit(PhoenixCommunicationSubject.AssetBranchChanged, this.assetBranches);
    }
  }

  public getTenantIds(): string[] {
    return _.map(this.tenants, 'id');
  }

  /*
  public hasPermission(permission: Permission): boolean {
    let permissionGranted: boolean;
    if ((this.tenants && this.tenants.length > 0) && (this.assetBranches && this.assetBranches.length > 0)) {
      const selectedTenant: AssetDto = this.tenants[0];
      const selectedAssetBranch: AssetDto = this.assetBranches[0];
      // get groups ids assigned to assets selected by user
      const userGroupIdsAssignedToUser: string[] = selectedAssetBranch.assignedUserGroups
        .filter((group: UserGroupDto) => group.assignedUsersIds.includes(this.user.id))
        .map((group: UserGroupDto) => group.id);
      // get groups names assigned to assets selected by user
      const hasDefaultManagementAdminRole: boolean = selectedAssetBranch.assignedUserGroups
        .filter((group: UserGroupDto) => group.assignedUsersIds.includes(this.user.id) && group.role == "admin" && group.name == "Default management admins")
        .length > 0;
      // check if user has admin role for particular groups assigned to selected root asset branch
      const hasUserAdminRole: boolean = this.user.roles.admin ? this.user.roles.admin
        .findIndex((role: string) => userGroupIdsAssignedToUser.includes(role.split('.')[0])) !== -1 : false;
      // check if user has user role for particular groups assigned to selected root asset branch
      const hasUserUserRole: boolean = this.user.roles.user ? this.user.roles.user
        .findIndex((role: string) => userGroupIdsAssignedToUser.includes(role.split('.')[0])) !== -1 : false;

      if (hasDefaultManagementAdminRole) {
        permissionGranted = permissionRole['DEFAULT_MANAGEMENT_ADMIN'].includes(permission.id);
      } else if (hasUserAdminRole) {
        permissionGranted = permissionRole['ADMIN'].includes(permission.id);
      } else if (hasUserUserRole) {
        permissionGranted = permissionRole['USER'].includes(permission.id);
      } else {
        _.forEach(this.user.roles, (tenants: string[], role: string) => {
          permissionGranted = _.includes(permissionRole[role.toUpperCase()], permission.id) && _.includes(tenants, selectedTenant.id);
          if (permissionGranted) {
            // End of for Each
            return false;
          }

        });
      }
    }
    if (!permissionGranted) {
      //console.log("++++ this.user:", this.user);
      permissionGranted = _.includes(permissionRole[Roles.EnvironmentAdmin.toUpperCase()], permission.id) && this.user.roles.hasOwnProperty(Roles.EnvironmentAdmin);
    }
    return permissionGranted;
  }
  */

  public hasPermission(permission: Permission): boolean {
    let permissionGranted: boolean;

    if ((this.tenants && this.tenants.length > 0) && (this.assetBranches && this.assetBranches.length > 0)) {
      const selectedTenant: AssetDto = this.tenants[0];
      const selectedAssetBranch: AssetDto = this.assetBranches[0];

      // get groups ids assigned to assets selected by user
      const userGroupIdsAssignedToUser: string[] = selectedAssetBranch.assignedUserGroups
        .filter((group: UserGroupDto) => group.assignedUsersIds.includes(this.user.id))
        .map((group: UserGroupDto) => group.id);

      // get groups names assigned to assets selected by user
      const hasDefaultManagementAdminRole: boolean = selectedAssetBranch.assignedUserGroups
        .filter((group: UserGroupDto) => group.assignedUsersIds.includes(this.user.id) && group.role == "admin" && group.name == "Default management admins")
        .length > 0;

      // check if user has admin role for particular groups assigned to selected root asset branch
      const hasUserAdminRole: boolean = this.user.roles.admin ? this.user.roles.admin
        .findIndex((role: string) => userGroupIdsAssignedToUser.includes(role.split('.')[0])) !== -1 : false;

      // check if user has user role for particular groups assigned to selected root asset branch
      const hasUserUserRole: boolean = this.user.roles.user ? this.user.roles.user
        .findIndex((role: string) => userGroupIdsAssignedToUser.includes(role.split('.')[0])) !== -1 : false;

      if (hasDefaultManagementAdminRole) {
        permissionGranted = permissionRole['DEFAULT_MANAGEMENT_ADMIN'].includes(permission.id);
      } else if (hasUserAdminRole) {
        permissionGranted = permissionRole['ADMIN'].includes(permission.id);
      } else if (hasUserUserRole) {
        permissionGranted = permissionRole['USER'].includes(permission.id);
      } else {
        _.forEach(this.user.roles, (tenants: string[], role: string) => {
          permissionGranted = _.includes(permissionRole[role.toUpperCase()], permission.id) && _.includes(tenants, selectedTenant.id);
          if (permissionGranted) {
            // End of for Each
            return false;
          }
        });
      }
    }

    if (!permissionGranted) {
      permissionGranted = _.includes(permissionRole[Roles.EnvironmentAdmin.toUpperCase()], permission.id) && this.user.roles.hasOwnProperty(Roles.EnvironmentAdmin);
    }

    return permissionGranted;
  }



  public async postlogin(): Promise<void> {
    console.log("####postLogin called");
    this.tenantSelectorDialogService.closeDialog()
    const [error, user] = await to(this.userApi.getCurrentUser().toPromise());
    if (error) {
      await this.router.navigate(['/login']);
      this.communicationService.emit(PhoenixCommunicationSubject.UserUnauthorized);
      return;
    }
    this.user = user;
    if (!this.user.accountInfo || !this.user.accountInfo.blocked) {


      this._availableTenants = await lastValueFrom(this.getAvailableTenants());
      localStorage.setItem('availableTenants', JSON.stringify(this._availableTenants.map((tenant: AssetDto) => tenant.id)));
      let selectedTenant = localStorage.getItem('selectedTenants');
      selectedTenant = selectedTenant ? JSON.parse(selectedTenant)[0] : null;
      if (!selectedTenant || !this._availableTenants.find(tenant => tenant.id === selectedTenant)) {
        this.router.navigate(['/login']);

        this.tenants = await lastValueFrom(this.tenantSelectorDialogService.showTenantSelectorDialog(this.availableTenants));
        this._availableAssetRootBranches = await lastValueFrom(this.getAvailableAssetBranchesForSelectedTenant());
        this.assetBranches = await lastValueFrom(this.assetBranchSelectorDialogService.showAssetBranchSelectorDialog(this._availableAssetRootBranches));
        this.communicationService.emit(PhoenixCommunicationSubject.AssetBranchChanged, this.assetBranches);

      }

      // get assigned groups
      this.updateGroupAssetPairs();
      this.findUserRolesFromAvailableTenants();
      this.communicationService.emit(PhoenixCommunicationSubject.UserLogin, { value: true });
      this.router.navigate(['/dashboard']);
    } else {
      await this.router.navigate(['/login']);
      this.communicationService.emit(PhoenixCommunicationSubject.UserIsBlocked);


    }


  }


  public async autoLogin(): Promise<any> {
    let currentUrl = this._startUrl
    const [error, userProfile] = await to(this.isUserLoggedIn());
    console.log('autoLogin error', error)
    console.log('autoLogin userProfile', userProfile)
    if (!error && userProfile) {

      try {

        const [err, user] = await to(this.userApi.getCurrentUser().toPromise());
        console.log('current user err, user', err, user)
        if (err) {

          await this.router.navigate(['/login']);
          this.communicationService.emit(PhoenixCommunicationSubject.UserUnauthorized);
          return;
        }
        this.user = user;
        if (!this.user.accountInfo?.blocked) {


          this._availableTenants = await lastValueFrom(this.getAvailableTenants());
          console.log('this._availableTenants', this._availableTenants)
          const currentTenants: string[] = JSON.parse(localStorage.getItem('selectedTenants'));
          console.log('currentTenants', currentTenants)
          if (currentTenants && currentTenants.length > 0) {
            let filteredTenants = this._availableTenants.filter((tenant: AssetDto) => currentTenants[0] === tenant.id);
            if (filteredTenants && filteredTenants.length > 0) {
              this.tenants = filteredTenants;
            }
          }
          console.log('this.tenants', this.tenants)
          console.log('this.tenantSelectorDialogService.isDialogOpen()', this.tenantSelectorDialogService.isDialogOpen())
          if (!this.tenants && !this.tenantSelectorDialogService.isDialogOpen()) {
            //this.postlogin()
            await this.router.navigate(['/login']);
            this.tenants = await lastValueFrom(this.tenantSelectorDialogService.showTenantSelectorDialog(this.availableTenants));
            console.log('this.tenants#2', this.tenants)
            this._availableAssetRootBranches = await lastValueFrom(this.getAvailableAssetBranchesForSelectedTenant());
            this.assetBranches = await lastValueFrom(this.assetBranchSelectorDialogService.showAssetBranchSelectorDialog(this._availableAssetRootBranches));
            console.log('this.assetBranches', this.assetBranches)
            this.communicationService.emit(PhoenixCommunicationSubject.AssetBranchChanged, this.assetBranches);


          }

          this._availableAssetRootBranches = await lastValueFrom(this.getAvailableAssetBranchesForSelectedTenant());
          const currentAssetBranch: string[] = JSON.parse(localStorage.getItem('selectedAssetBranches'));
          console.log('this._availableAssetRootBranches', this._availableAssetRootBranches)
          console.log('currentAssetBranch', currentAssetBranch)
          if (!currentAssetBranch && !this.assetBranchSelectorDialogService.isDialogOpen()) {
            console.log('should show asset branch selector dialog')
            await this.router.navigate(['/login']);
            this.assetBranches = await lastValueFrom(this.assetBranchSelectorDialogService.showAssetBranchSelectorDialog(this._availableAssetRootBranches));
            this.communicationService.emit(PhoenixCommunicationSubject.AssetBranchChanged, this.assetBranches);

          } else if (currentAssetBranch) {
            let filteredAssetBranches = this._availableAssetRootBranches.filter((branch: AssetDto) => currentAssetBranch[0] === branch.id);
            if (filteredAssetBranches && filteredAssetBranches.length > 0) {
              this.assetBranches = filteredAssetBranches;
              this.communicationService.emit(PhoenixCommunicationSubject.AssetBranchChanged, this.assetBranches);
            }
          }

          // get assigned groups
          this.updateGroupAssetPairs();
          this.findUserRolesFromAvailableTenants();

          this.communicationService.emit(PhoenixCommunicationSubject.UserLogin, { value: true });
          if (currentUrl === '' || currentUrl === '/' || currentUrl === '/login') {
            this.router.navigate([this._defaultUrl]);
          } else {
            this.router.navigate([currentUrl]);
          }
        } else {
          await this.router.navigate(['/login']);
          this.communicationService.emit(PhoenixCommunicationSubject.UserIsBlocked);


        }
      } catch (e) {
        console.log('autoLogin error', e)

        //  this.logout();
      }

    }
    else {
      setTimeout(() => {
        console.log('reload')
        localStorage.clear()
        this.postlogin()
        //window.location.reload();
      }, 500)
    }

  }



  public logout(): void {
    this.authApi.logout();
    // is true when logout manually
    //this.oauthService.logOut();

    //this.router.navigate(['/login']);

    this.oauthService.revokeTokenAndLogout(undefined, true);


    if (window.sessionStorage) {
      window.sessionStorage.clear();
    }

    // localStorage.clear();
    if (this.tokenExpirationTimer) {
      clearTimeout(this.tokenExpirationTimer);
    }
    //this.communicationService.emit(PhoenixCommunicationSubject.UserLogout, { value: true });
  }


  /*
  public autoLogoutAfterPeriodTime(expirationDuration: number): void {
    if (this.tokenExpirationTimer) {
      clearInterval(this.tokenExpirationTimer);
    }
    this.tokenExpirationTimer = setTimeout(() => {
      this.logout();
    }, expirationDuration);
  }
  */

  /*
  public startNewAutoLoginCountDown(): void {
    if (!this.userIsNotAuthenticated()) {
      this.autoLogoutAfterPeriodTime(this.autoLogoutAfter * 1000);
    }
  }
  */

  public routeNeedsPermission(route: ActivatedRouteSnapshot | Route): boolean {
    return _.hasIn(route, 'data.permission');
  }

  public userIsNotAuthenticated(): boolean {
    return !this.oauthService.hasValidAccessToken();
    //return !Boolean(this.authApi.getAuth());
  }


  public async isUserLoggedIn(): Promise<boolean> {
    const [error, userProfile] = await to(this.oauthService.loadUserProfile());
    return !error && userProfile ? true : false;

  }

  public updateGroupAssetPairs(): void {
    if (this.assetBranches && this._assetBranches.length > 0) {
      const groupAssetPairs: string[] = this.assetBranches[0].assignedUserGroups
        .filter((assignedGroup: UserGroupDto) => assignedGroup.assignedUsersIds.includes(this.user.id))
        .map((assignedGroup: UserGroupDto) => `${assignedGroup.id}:${this.assetBranches[0].id}`);
      this.authApi.setGroupAssetPairs(groupAssetPairs);
    }
  }

  private findUserRolesFromAvailableTenants(): void {
    this.rolesWithTenantNames = {};
    // _.forEach(this.user.roles, (tenants: string[], role: string) => {
    //   this.rolesWithTenantNames[role] = _.map(tenants, (tenant: string) => {
    //     return _.get(_.find(this.availableTenants, {id: tenant}), 'name');
    //   });
    //
    //   _.remove(this.rolesWithTenantNames[role], _.isUndefined);
    // });
  }

  private getAvailableAssetBranchesForSelectedTenant(): Observable<AssetDto[]> {
    if (this.user) {
      return this.assetApi.getAssetBranches(this.user.id);
    }
    return of([]);
  }


  public isAdmin(): boolean {
    const selectedTenant: AssetDto = this.tenants[0];
    const selectedAssetBranch: AssetDto = this.assetBranches[0];
    // get groups ids assigned to assets selected by user
    const userGroupIdsAssignedToUser: string[] = selectedAssetBranch.assignedUserGroups
      .filter((group: UserGroupDto) => group.assignedUsersIds.includes(this.user.id))
      .map((group: UserGroupDto) => group.id);
    // get groups names assigned to assets selected by user
    const hasDefaultManagementAdminRole: boolean = selectedAssetBranch.assignedUserGroups
      .filter((group: UserGroupDto) => group.assignedUsersIds.includes(this.user.id) && group.role == "admin" && group.name == "Default management admins")
      .length > 0;
    // check if user has admin role for particular groups assigned to selected root asset branch
    const hasUserAdminRole: boolean = this.user.roles.admin ? this.user.roles.admin
      .findIndex((role: string) => userGroupIdsAssignedToUser.includes(role.split('.')[0])) !== -1 : false;
    return hasDefaultManagementAdminRole; //|| hasUserAdminRole;
  }
}
