// tslint:disable:max-file-line-count
import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash-es';

import { PhoenixDialogResult } from '../../components/phoenix-dialog/classes/phoenix-dialog-result';
import { PhoenixDynamicFormControl } from '../../components/phoenix-dialog/classes/phoenix-dynamic-form-control';
import { PhoenixDynamicFormControlType } from '../../components/phoenix-dialog/enums/phoenix-dynamic-form-control-type.enum';
import { PhoenixDialogData } from '../../components/phoenix-dialog/interfaces/phoenix-dialog-data';
import { PhoenixDialogComponent } from '../../components/phoenix-dialog/phoenix-dialog/phoenix-dialog.component';
import { PhoenixSnackbarService } from '../../components/phoenix-snackbar/phoenix-snackbar.service';
import { PhoenixIcon } from '../../enums/phoenix-icon.enum';
import { PhoenixStatusColor } from '../../enums/phoenix-status-color.enum';
import { ThresholdDto } from '../../gapicon/dto.module';
import { ThresholdApiService } from '../../gapicon/threshold/services/threshold-api.service';
import { PhoenixCommunicationSubject } from '../phoenix-communication-service/phoenix-communication-subject.enum';
import { PhoenixCommunicationService } from '../phoenix-communication-service/phoenix-communication.service';

import { PhoenixWizardHelperService } from './phoenix-wizard-helper.service';
import { PhoenixAutocompleteEntry } from '@phoenix/components/phoenix-dialog/classes/phoenix-autocomplete-entry';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PhoenixThresholdWizardService {

  private editThreshold: ThresholdDto = undefined;
  private openElementWizard: () => void = undefined;

  public constructor(
    private dialog: MatDialog,
    private thresholdApiService: ThresholdApiService,
    private communicationService: PhoenixCommunicationService,
    private translateService: TranslateService,
    private phoenixWizardHelper: PhoenixWizardHelperService,
    private phoenixSnackbarService: PhoenixSnackbarService,
  ) {
  }

  public openCreateThresholdWizard(threshold: ThresholdDto, openElementWizard: () => void, afterThresholdSavedCallback?: (threshold: ThresholdDto) => void): void {
    this.openElementWizard = openElementWizard;
    this.openThresholdWizard(
      threshold,
      (dialogResult: PhoenixDialogResult): Promise<void> => this.onNewThresholdCloseCallback(dialogResult, afterThresholdSavedCallback),
      'WIZARDS.SELECTOR.TITLE.THRESHOLD.CREATE',
    );
  }

  public openEditThresholdPointWizard(threshold: ThresholdDto): void {
    this.editThreshold = threshold;
    this.openElementWizard = undefined;
    this.openThresholdWizard(
      threshold,
      (dialogResult: PhoenixDialogResult): Promise<void> => this.onEditThresholdCloseCallback(dialogResult),
      'WIZARDS.SELECTOR.TITLE.THRESHOLD.EDIT',
    );
  }

  public openThresholdWizard(threshold: ThresholdDto, onClose: Function, title: string): void {
    this.dialog.open(PhoenixDialogComponent, {
      width: this.phoenixWizardHelper.DIALOGWIDTH,
      autoFocus: false,
      data: <PhoenixDialogData>{
        headline: title,
        subHeadline: 'WIZARDS.SELECTOR.SUBTITLE.THRESHOLD',
        onClose: onClose,
        buttons: {
          cancel: true,
          previous: !!this.openElementWizard,
          save: true,
        },
        formControls: [
          this.createThresholdFormControl(
            'createThreshold',
            'WIZARDS.SELECTOR.TEXTBOX.NAME',
            'WIZARDS.SELECTOR.TEXTBOX.DESCRIPTION',
            'WIZARDS.SELECTOR.DESCRIPTION.THRESHOLDVALUETYPE',
            'WIZARDS.SELECTOR.DESCRIPTION.AREAS',
            'WIZARDS.SELECTOR.DESCRIPTION.ALERT',
            threshold,

          ),
        ],
      },
    });
  }

  public openDeleteThresholdWizard(threshold: ThresholdDto): void {
    this.editThreshold = threshold;
    const randomString: string = this.phoenixWizardHelper.getRandomString();
    const hintTextOptions: { classes: { [key: string]: boolean } } = {
      classes: {
        'font-size-12': true,
        'hint-text': true,
        'primary-300-fg': true,
        'mt-4': true,
        'mb-4': true,
      },
    };

    this.dialog.open(PhoenixDialogComponent, {
      width: this.phoenixWizardHelper.DIALOGWIDTH,
      autoFocus: false,
      data: <PhoenixDialogData>{
        headline: 'WIZARDS.THRESHOLDS.DELETE.TITLE',
        subHeadline: threshold.name,
        buttons: {
          cancel: true,
          delete: 'WIZARDS.THRESHOLDS.DELETE.SUBMIT',
        },
        onClose: (dialogResult: PhoenixDialogResult): Promise<void> => this.onDeleteCloseCallback(dialogResult),
        translationParams: {
          deleteCode: randomString,
          linkedMeasurementpointCount: threshold.assetsCount.toString(),
        },
        color: 'warn',
        formControls: [[
          new PhoenixDynamicFormControl<string>(
            'assetName',
            PhoenixDynamicFormControlType.Text,
            undefined,
            false,
            hintTextOptions,
            'WIZARDS.THRESHOLDS.DELETE.LINE1',
          ),
          new PhoenixDynamicFormControl<string>(
            'assetName',
            PhoenixDynamicFormControlType.Text,
            undefined,
            false,
            hintTextOptions,
            'WIZARDS.THRESHOLDS.DELETE.LINE2',
          ),
          new PhoenixDynamicFormControl<string>(
            'icon1',
            PhoenixDynamicFormControlType.Icon,
            threshold.assetsCount.toString(),
            false,
            { size: 40, classes: { 'hint-text': true } },
            PhoenixIcon.FIBER_MANUAL_RECORD,
          ),
          new PhoenixDynamicFormControl<string>(
            'assetName',
            PhoenixDynamicFormControlType.Text,
            undefined,
            false,
            hintTextOptions,
            'WIZARDS.THRESHOLDS.DELETE.CONFIRMATION',
          ),
          new PhoenixDynamicFormControl<string>(
            'delete-code',
            PhoenixDynamicFormControlType.Text,
            'WIZARDS.THRESHOLDS.DELETE.DELETECODE',
            false,
            undefined,
            randomString,
          ),
          new PhoenixDynamicFormControl<string>(
            'delete',
            PhoenixDynamicFormControlType.TextBox,
            'WIZARDS.THRESHOLDS.DELETE.DELETECODEINPUT',
            true,
            { hint: 'WIZARDS.THRESHOLDS.DELETE.DELETECODEINPUTHINT' },
            undefined,
            undefined,
            [Validators.required, Validators.pattern(randomString)],
          ),
        ]],
      },
    });
  }

  private openFollowingThresholdWizard(threshold: ThresholdDto): void {
    this.dialog.open(PhoenixDialogComponent, {
      width: '800px',
      autoFocus: false,
      data: <PhoenixDialogData>{
        headline: 'WIZARDS.SELECTOR.TITLE.FOLLOWINGTHRESHOLD',
        subHeadline: 'WIZARDS.SELECTOR.SUBTITLE.FOLLOWING',
        buttons: {
          exit: true,
        },
        translationParams: {
          thresholdName: threshold.name,
        },
        onClose: (dialogResult: PhoenixDialogResult): Promise<void> => this.onFollowingCloseCallback(dialogResult),
        formControls: [
          this.createElementSelectionWizardColumn(
            'DuplicateThreshold',
            PhoenixIcon.REPEAT,
            'WIZARDS.COMMON.DUPLICATETHRESHOLD',
            'WIZARDS.SELECTOR.DESCRIPTION.DUPLICATETHRESHOLD',
            'WIZARDS.SELECTOR.BUTTON.DUPLICATETHRESHOLD',
          ),
          this.createElementSelectionWizardColumn(
            'NewThreshold',
            PhoenixIcon.PLAY_ARROW,
            'WIZARDS.COMMON.NEWTHRESHOLD',
            'WIZARDS.SELECTOR.DESCRIPTION.NEWTHRESHOLD',
            'WIZARDS.SELECTOR.BUTTON.NEWTHRESHOLD',
          ),
          this.createElementSelectionWizardColumn(
            'NavigationToThresholdDashboard',
            PhoenixIcon.VIEW_MODULE,
            'WIZARDS.COMMON.GOTOTHRESHOLDDASHBOARD',
            'WIZARDS.SELECTOR.DESCRIPTION.GOTOTHRESHOLDDASHBOARD',
            'WIZARDS.SELECTOR.BUTTON.GOTOTHRESHOLDDASHBOARD',
          ),
        ],
      },
    })
      ;
  }

  private async onFollowingCloseCallback(dialogResult: PhoenixDialogResult): Promise<void> {
    switch (dialogResult.formData['buttonClicked'].value) {
      case 'buttonDuplicateThreshold':
        this.openCreateThresholdWizard(this.editThreshold, this.openElementWizard, (threshold: ThresholdDto) => {
          this.openFollowingThresholdWizard(threshold);
        },
        );
        break;
      case 'buttonNewThreshold':
        this.openCreateThresholdWizard(undefined, this.openElementWizard, (threshold: ThresholdDto) => {
          this.openFollowingThresholdWizard(threshold);
        },
        );
        break;
      case 'NavigationToThresholdDashboard':
        break;
    }
    dialogResult.dialogRef.close();
  }

  private async onNewThresholdCloseCallback(dialogResult: PhoenixDialogResult, afterSaveCallback: (threshold: ThresholdDto) => void): Promise<void> {
    if (dialogResult.result === 'save') {
      const threshold: ThresholdDto = this.createThreshold(new ThresholdDto(), dialogResult);
      try {
        this.editThreshold = await this.thresholdApiService.createThreshold(threshold).toPromise();
        this.phoenixSnackbarService.openPhoenixDefaultSnackbar(this.translateService.instant('WIZARDS.THRESHOLDS.CREATE.SUCCESS'));
      } catch (e) {
        console.error(e);
      }

      this.communicationService.emit(PhoenixCommunicationSubject.ReloadThresholdPage);
      if (afterSaveCallback) {
        afterSaveCallback(this.editThreshold);
      } else {
        this.openElementWizard = (): void => this.openFollowingThresholdWizard(this.editThreshold);
        this.openFollowingThresholdWizard(this.editThreshold);
      }
    }

    if (dialogResult.result === 'previous' && this.openElementWizard !== undefined) {
      this.openElementWizard();
    }
    dialogResult.dialogRef.close();
  }

  private async onEditThresholdCloseCallback(dialogResult: PhoenixDialogResult): Promise<void> {
    if (dialogResult.result === 'save') {
      this.editThreshold = this.createThreshold(this.editThreshold, dialogResult);
      try {
        await this.thresholdApiService.updateThreshold(this.editThreshold).toPromise();
        this.phoenixSnackbarService.openPhoenixDefaultSnackbar(this.translateService.instant('WIZARDS.THRESHOLDS.EDIT.SUCCESS'));
      } catch (e) {
        console.error(e);
      }

      this.communicationService.emit(PhoenixCommunicationSubject.ReloadThresholdPage);
    }
    dialogResult.dialogRef.close();
  }

  private async onDeleteCloseCallback(dialogResult: PhoenixDialogResult): Promise<void> {
    if (dialogResult.result === 'delete') {
      try {
        await this.thresholdApiService.deleteThreshold(this.editThreshold).toPromise();
        this.phoenixSnackbarService.openPhoenixDefaultSnackbar(this.translateService.instant('WIZARDS.THRESHOLDS.DELETE.SUCCESS'));
      } catch (e) {
        console.error(e);
      }
      this.communicationService.emit(PhoenixCommunicationSubject.ReloadThresholdPage);
    }
    dialogResult.dialogRef.close();
  }

  private createThreshold(threshold: ThresholdDto, dialogResult: PhoenixDialogResult): ThresholdDto {
    threshold.name = dialogResult.formData['txtName'];
    threshold.description = dialogResult.formData['txtDescription'];
    threshold.alarmMax = dialogResult.formData['sliderUpperLimit'];
    threshold.alarmMin = dialogResult.formData['sliderLowerLimit'];
    threshold.valueMax = dialogResult.formData['sliderUpperLimit'];
    threshold.valueMin = dialogResult.formData['sliderLowerLimit'];
    threshold.alarmConfig = dialogResult.formData['alarmConfig'];
    return threshold;
  }

  private createElementSelectionWizardColumn(
    key: string,
    icon: string,
    iconLabel: string,
    text: string,
    buttonLabel: string,
    flipIcon?: boolean,
  ): PhoenixDynamicFormControl<string | boolean>[] {
    return [
      new PhoenixDynamicFormControl<string>(
        'icon' + key,
        PhoenixDynamicFormControlType.Icon,
        iconLabel,
        false,
        {
          size: 60,
          body: text,
          flip: flipIcon,
        },
        icon,
      ),
      new PhoenixDynamicFormControl<boolean>(
        'button' + key,
        PhoenixDynamicFormControlType.Button,
        buttonLabel,
        false,
        {
          style: 'raised',
          color: 'basic',
        },
      ),
    ];
  }

  private createThresholdFormControl(
    key: string,
    lblName: string,
    lblDescription: string,
    lblValue: string,
    lblAreas: string,
    lblAlert: string,
    threshold: ThresholdDto,

  ): PhoenixDynamicFormControl<string | boolean | number | PhoenixAutocompleteEntry<String>>[] {

    const alarmConfigData: PhoenixAutocompleteEntry<String>[] =
      _.sortBy(
        [new PhoenixAutocompleteEntry({ label: this.translateService.instant("WIZARDS.SELECTOR.ALARM.ALWAYS"), object: "ALWAYS" }),
        new PhoenixAutocompleteEntry({ label: this.translateService.instant("WIZARDS.SELECTOR.ALARM.FIRST"), object: "FIRST" }),
        new PhoenixAutocompleteEntry({ label: this.translateService.instant("WIZARDS.SELECTOR.ALARM.SECOND"), object: "SECOND" })],
        [(element: PhoenixAutocompleteEntry<String>): string => element.label.toLowerCase()]);
    const alarmConfig: string = _.get(threshold, 'alarmConfig', 'FIRST');
    const alarmConfigFormControl: PhoenixDynamicFormControl<PhoenixAutocompleteEntry<String>> =
      new PhoenixDynamicFormControl<PhoenixAutocompleteEntry<String>>(
        'alarmConfig',
        PhoenixDynamicFormControlType.TextBox,
        'WIZARDS.SELECTOR.TEXTBOX.ALARMMAILS',
        false,
        { autocomplete: (): Observable<PhoenixAutocompleteEntry<String>[]> => of(alarmConfigData) },
        new PhoenixAutocompleteEntry({ label: this.translateService.instant("WIZARDS.SELECTOR.ALARM." + (alarmConfig || "FIRST")), object: alarmConfig || "FIRST" }),
      );


    return [
      new PhoenixDynamicFormControl<string>(
        'txtName',
        PhoenixDynamicFormControlType.TextBox,
        lblName,
        true,
        {},
        _.get(threshold, 'name', undefined),
      ),
      new PhoenixDynamicFormControl<string>(
        'txtDescription',
        PhoenixDynamicFormControlType.TextArea,
        lblDescription,
        false,
        {},
        _.get(threshold, 'description', undefined),
      ),
      new PhoenixDynamicFormControl<string>(
        'txtValue',
        PhoenixDynamicFormControlType.Dropdown,
        lblValue,
        true,
        {
          options: [
            { key: 'temperature', label: 'WIZARDS.SELECTOR.THRESHOLD.TEMPERATURE_IN_C' },
          ],
        },
        //threshold ? 'temperature' : undefined,
        'temperature'
      ),
      new PhoenixDynamicFormControl<string>(
        'txtAreas',
        PhoenixDynamicFormControlType.Text,
        '',
        true,
        {},
        lblAreas,
      ),
      //alarmConfigFormControl,

      new PhoenixDynamicFormControl<string>(
        'iconUpperLimit',
        PhoenixDynamicFormControlType.Icon,
        undefined,
        false,
        {
          size: 30,
          color: PhoenixStatusColor.NEGATIVE,
        },
        PhoenixIcon.FIBER_MANUAL_RECORD,
      ),
      new PhoenixDynamicFormControl<number>(
        'sliderUpperLimit',
        PhoenixDynamicFormControlType.ThresholdLimit,
        'Max:',
        false,
        {},
        parseFloat(_.get(threshold, 'alarmMax', '0')),
      ),
      new PhoenixDynamicFormControl<string>(
        'iconOk',
        PhoenixDynamicFormControlType.Icon,
        undefined,
        false,
        {
          size: 30,
          color: PhoenixStatusColor.POSITIVE,
        },
        PhoenixIcon.FIBER_MANUAL_RECORD,
      ),
      new PhoenixDynamicFormControl<number>(
        'sliderLowerLimit',
        PhoenixDynamicFormControlType.ThresholdLimit,
        'Min:',
        false,
        {},
        parseFloat(_.get(threshold, 'alarmMin', '0')),
      ),
      new PhoenixDynamicFormControl<string>(
        'iconLowerLimit',
        PhoenixDynamicFormControlType.Icon,
        undefined,
        false,
        {
          size: 30,
          color: PhoenixStatusColor.NEGATIVE,
        },
        PhoenixIcon.FIBER_MANUAL_RECORD,
      ),

      new PhoenixDynamicFormControl<string>(
        'alarmConfig',
        PhoenixDynamicFormControlType.Dropdown,
        lblAlert,
        true,
        {
          options: [
            { key: "FIRST", label: this.translateService.instant("WIZARDS.SELECTOR.ALARM.FIRST") },
            { key: "SECOND", label: this.translateService.instant("WIZARDS.SELECTOR.ALARM.SECOND") },
            { key: "ALWAYS", label: this.translateService.instant("WIZARDS.SELECTOR.ALARM.ALWAYS") },
          ],
        },
        alarmConfig ? alarmConfig : "FIRST",
      ),

    ];
  }
}


