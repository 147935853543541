import { Component, OnDestroy } from '@angular/core';
import { Event, NavigationEnd, Router, RouterEvent } from '@angular/router';
import * as _ from 'lodash-es';

import { Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { of } from 'rxjs';


import { NavigationItem } from '../../../app/navigation/classes/navigation-item';
import { NavigationService } from '../../../app/navigation/navigation.service';
import { PhoenixIcon } from '../../enums/phoenix-icon.enum';
import { AssetApiService } from '../../gapicon/asset/services/asset-api.service';
import { AssetDto, ThresholdDto, UserDto } from '../../gapicon/dto.module';
import { ThresholdApiService } from '../../gapicon/threshold/services/threshold-api.service';
import { UserApiService } from '../../gapicon/user/services/user-api.service';

import { PhoenixBreadcrumb } from './phoenix-breadcrumb';

@Component({
  selector: 'phoenix-breadcrumbs',
  templateUrl: './phoenix-breadcrumbs.component.html',
  styleUrls: ['./phoenix-breadcrumbs.component.scss'],
})
export class PhoenixBreadcrumbsComponent implements OnDestroy {
  public breadcrumbs: PhoenixBreadcrumb[] = [];
  private subscription: Subscription;

  public constructor(
    private router: Router,
    private navigationService: NavigationService,
    private assetApiService: AssetApiService,
    private thresholdApiService: ThresholdApiService,
    private userApiService: UserApiService,
  ) {
    this.subscription = this.router.events.pipe(
      filter((event: | Event | RouterEvent) => {
        return event instanceof NavigationEnd;
      }),
      map((event: NavigationEnd) => this.buildBreadCrumb(event)),
    ).subscribe((result: PhoenixBreadcrumb[]) => this.breadcrumbs = result);
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private buildBreadCrumb(route: NavigationEnd): PhoenixBreadcrumb[] {
    const breadcrumbs: PhoenixBreadcrumb[] = [];
    const urlParts: string[] = route.url.toString().split('/');
    _.forEach(urlParts, (urlPart: string) => {
      console.log('urlPart', urlPart)
      if (urlPart === '') {
        breadcrumbs.push({
          icon: PhoenixIcon.HOME,
          label: of(''),
          url: '',
          idResolver: (id: string): Observable<string> => of('Dashboard'),
        });
      } else {

        const navigationItem: NavigationItem = this.navigationService.getNavigationElementByPath(
          '/' + urlPart,
        );

        const fallbackLabel: string = this.getPathPartLabel(urlPart);
        //debugger;

        if (navigationItem) { // Navigation item exists for path part
          breadcrumbs.push({
            label: of(navigationItem.translate),
            url: _.last(breadcrumbs).url + '/' + urlPart,
            idResolver: this.getIdResolver(navigationItem.translate),
          });
        } else if (!navigationItem && fallbackLabel) { // Navigation item doesn't exisists but we have an fallbacklabel
          breadcrumbs.push({
            label: of(undefined),
            url: _.last(breadcrumbs).url + '/' + urlPart,
            idResolver: this.getIdResolver(fallbackLabel),
          });
        } else if (!navigationItem && !fallbackLabel) { // Navigation item and fallbacklabel doesn't exist so it has to be an id of previous path part
          breadcrumbs.push({
            label: _.last(breadcrumbs).idResolver(this, urlPart),
            url: _.last(breadcrumbs).url + '/' + urlPart,
            idResolver: _.last(breadcrumbs).idResolver,
          });
        }
      }
    });
    return breadcrumbs;
  }

  private getIdResolver(type: string): Function {
    switch (type) {
      case 'NAV.CHECKPOINTS':
        return this.assetIdResolver;
      case 'NAV.NOTIFICATIONS':
        return this.notificationIdResolver;
      case 'NAV.ASSETS':
        return this.assetIdResolver;
      case 'NAV.THRESHOLDS':
        return this.thresholdIdResolver;
      case 'NAV.USER':
        return this.userIdResolver;
      default:
        return (context: PhoenixBreadcrumbsComponent, id: string): Observable<string> => of('Unknown type ' + type + ' for id ' + id);
    }
  }

  private getPathPartLabel(pathPart: string): string {
    switch (pathPart) {
      case 'asset':
        return 'NAV.ASSETS';
      case 'checkpoint':
        return 'NAV.CHECKPOINTS';
      default:
        return undefined;
    }
  }

  private assetIdResolver(context: PhoenixBreadcrumbsComponent, id: string): Observable<string> {
    return context.assetApiService.getAssetById(id).pipe(
      map((assetDto: AssetDto) => assetDto ? assetDto.name : ""),
    );
  }

  private thresholdIdResolver(context: PhoenixBreadcrumbsComponent, id: string): Observable<string> {
    return context.thresholdApiService.getThresholdById(id).pipe(
      map((threshold: ThresholdDto) => threshold.name),
    );
  }

  private userIdResolver(context: PhoenixBreadcrumbsComponent, id: string): Observable<string> {
    return context.userApiService.getUserById(id).pipe(
      map((userDto: UserDto) => userDto.firstName + ' ' + userDto.lastName + ' (' + userDto.email + ')'),
    );
  }

  // TODO: Korrekte Anzeige mit i18n sobald Meldungen implementiert sind
  private notificationIdResolver(context: PhoenixBreadcrumbsComponent, id: string): Observable<string> {
    return of('Meldung #' + id);
  }

}
