import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash-es';
import { Observable, Observer } from 'rxjs';

import { PhoenixDialogButton } from '../../../components/phoenix-dialog-new/classes/phoenix-dialog-button';
import { PhoenixFormElement } from '../../../components/phoenix-form/interfaces/phoenix-form-element';
import { DropDownEntry, PhoenixFormDropdown } from '../../../components/phoenix-form/phoenix-form-dropdown/classes/phoenix-form-dropdown';
import { PhoenixFormText } from '../../../components/phoenix-form/phoenix-form-text/classes/phoenix-form-text';
import { PhoenixFormTextarea } from '../../../components/phoenix-form/phoenix-form-textarea/classes/phoenix-form-textarea';
import { PhoenixSnackbarService } from '../../../components/phoenix-snackbar/phoenix-snackbar.service';
import { PhoenixTableLink } from '../../../components/phoenix-table/classes/phoenix-table-link';
import { AssetDto } from '../../../gapicon/asset/dto/asset-dto';
import { AssetTags } from '../../../gapicon/asset/dto/asset-tags';
import { SensorTypeDto } from '../../../gapicon/dto.module';
import { SensorConfigDto } from '../../../gapicon/sensor/dto/sensor-config-dto';
import { SensorConfigValueDto } from '../../../gapicon/sensor/dto/sensor-config-value-dto';
import { SensorDto } from '../../../gapicon/sensor/dto/sensor-dto';
import { SensorRegistrationDto } from '../../../gapicon/sensor/dto/sensor-registration-dto';
import { SensorSearchCriteriaDto } from '../../../gapicon/sensor/dto/sensor-search-criteria-dto';
import { SensorApiService } from '../../../gapicon/sensor/services/sensor-api.service';
import { PhoenixCommunicationSubject } from '../../../services/phoenix-communication-service/phoenix-communication-subject.enum';
import { PhoenixCommunicationService } from '../../../services/phoenix-communication-service/phoenix-communication.service';
import { PhoenixNavigationService } from '../../../services/phoenix-navigation-service/phoenix-navigation.service';

import { PhoenixSensormanagementDialog } from './classes/phoenix-sensormanagement-dialog';
import { PhoenixSensormanagementInformationDialog } from './classes/phoenix-sensormanagement-information-dialog';
import { PhoenixSensormanagementDialogComponent } from './phoenix-sensormanagement-dialog.component';
import { PhoenixSensormanagementInformationDialogComponent } from './phoenix-sensormanagement-information-dialog/phoenix-sensormanagement-information-dialog.component';
import Timer = NodeJS.Timer;


@Injectable({
  providedIn: 'root',
})
export class PhoenixSensormanagementDialogService {

  private debouncer: Timer;
  private formGroup: UntypedFormGroup;

  public constructor(private dialog: MatDialog,
    private sensorApiService: SensorApiService,
    private communicationService: PhoenixCommunicationService,
    private snackbarService: PhoenixSnackbarService,
    private translateService: TranslateService,
    private navigationService: PhoenixNavigationService,
  ) {
  }

  public openSensormanagementInformationDialog(assets: AssetDto[]): MatDialogRef<PhoenixSensormanagementInformationDialogComponent> {
    const dialogRef: MatDialogRef<PhoenixSensormanagementInformationDialogComponent> = this.dialog.open(PhoenixSensormanagementInformationDialogComponent, {
      autoFocus: false,
      maxWidth: 800,
      data: <PhoenixSensormanagementInformationDialog>{
        title: this.translateService.instant('MEASUREMENTTYPEINFORMATIONDIALOG.TITLE'),
        subtitle: 'MEASUREMENTTYPEINFORMATIONDIALOG.SUBTITLE',
        buttons: [
          new PhoenixDialogButton({
            label: 'MEASUREMENTTYPEINFORMATIONDIALOG.CLOSEBUTTON',
            click: (): void => dialogRef.close(),
          }),
        ],
        links: this.mapAssetsToLinks(assets, (): void => dialogRef.close()),
      },
    });
    return dialogRef;
  }

  public async openSensorDialog(assetDto?: AssetDto, sensorType?: SensorTypeDto): Promise<MatDialogRef<PhoenixSensormanagementDialogComponent>> {

    this.formGroup = new UntypedFormGroup({
      sensorName: new UntypedFormControl(assetDto ? assetDto.name : '', [Validators.required]),
      sensorDescription: new UntypedFormControl(assetDto ? assetDto.description : ''),
      sensorType: new UntypedFormControl(sensorType, [Validators.required]),
      sensorId: new UntypedFormControl(assetDto ? assetDto.sensor.sensorId : '', [Validators.required], this.forbiddenSensorIdValidator(_.get(assetDto, 'sensor.sensorId'))),
    });
    const sensorTypes: SensorTypeDto[] = assetDto ?
      [sensorType] :
      await this.sensorApiService.getSensorTypes().toPromise();

    if (assetDto) {
      this.formGroup.get('sensorType').disable();

    } else if (!assetDto && sensorTypes.length > 0) {
      this.formGroup.get('sensorType').setValue(sensorTypes[0])
    }


    const formElements: PhoenixFormElement[][] = this.getElements(sensorTypes);
    const isAutomaticCounter = sensorType ? sensorType.measurementTypes.includes("automaticCounter") : false;
    const dialogRef: MatDialogRef<PhoenixSensormanagementDialogComponent> = this.dialog.open(PhoenixSensormanagementDialogComponent, {
      width: '550px',
      autoFocus: false,

      data: <PhoenixSensormanagementDialog>{
        color: isAutomaticCounter ? "warn" : 'accent',
        title: 'WIZARDS.SENSOR' + (assetDto ? '.EDIT' : '') + '.HEADLINE',
        subtitle: 'WIZARDS.SENSOR' + (assetDto ? '.EDIT' : '') + '.SUBHEADLINE',
        buttons: [
          new PhoenixDialogButton({ label: 'WIZARDS.SENSOR.CANCEL', click: (): void => dialogRef.close(undefined) }),
          new PhoenixDialogButton({
            label: 'WIZARDS.SENSOR.SAVE', click: async (): Promise<void> => {
              if (assetDto) { // create new sensor registration
                assetDto = await this.updateSensor(this.formGroup, assetDto);
              } else { // update sensor asset
                assetDto = await this.createSensorRegistration(this.formGroup);
              }

              this.communicationService.emit(PhoenixCommunicationSubject.ReloadSensorPage);
              dialogRef.close(assetDto);
            }, disabled: (): boolean => {
              return this.formGroup.invalid || this.formGroup.pending;
            }, color: 'accent', raised: true,
          })],

        formGroup: this.formGroup,
        elements: formElements,
        sensorConfigPreset: assetDto ? assetDto.sensor.wantedConfiguration.configurations : undefined,
        sensorTypeDropdown: formElements[3][0],
        showRegistrationParams: assetDto === undefined,
      },
    });

    return dialogRef;
  }

  private mapAssetsToLinks(assets: AssetDto[], closeDialog: () => void): PhoenixTableLink[] {
    const linkList: PhoenixTableLink[] = [];
    for (const asset of assets) {
      linkList.push(new PhoenixTableLink(asset.name, async (): Promise<void> => {
        await this.navigationService.navigateToAsset(asset);
        closeDialog();
      }));
    }
    return linkList;
  }

  private async createSensorRegistration(formGroup: UntypedFormGroup): Promise<AssetDto> {
    const sensorRegistrationDto: SensorRegistrationDto = new SensorRegistrationDto({
      registrationParams: this.applyFormValuesToSensorConfig(
        formGroup.get('REGISTRATIONCONFIG') as UntypedFormGroup,
        new SensorTypeDto(formGroup.get('sensorType').value).registrationParams,
      ),
      sensorAsset: this.applyFormValuesToSensorDto(formGroup),
    });

    const sensor: AssetDto = await this.sensorApiService.registerSensor(sensorRegistrationDto).toPromise();
    this.snackbarService.openPhoenixDefaultSnackbar(this.translateService.instant('WIZARDS.SENSOR.CREATE.SUCCESS'));

    return sensor;
  }

  private async updateSensor(formGroup: UntypedFormGroup, assetDto: AssetDto): Promise<AssetDto> {
    this.applyFormValuesToSensorConfig(formGroup.get('SENSORCONFIG') as UntypedFormGroup, assetDto.sensor.wantedConfiguration.configurations);
    this.applyFormValuesToSensorDto(formGroup, assetDto);

    assetDto = await this.sensorApiService.updateSensor(assetDto).toPromise();
    this.snackbarService.openPhoenixDefaultSnackbar(this.translateService.instant('WIZARDS.SENSOR.EDIT.SUCCESS'));
    return assetDto;
  }

  private applyFormValuesToSensorDto(formGroup: UntypedFormGroup, assetDto: AssetDto = new AssetDto()): AssetDto {

    assetDto.name = formGroup.get('sensorName').value;
    assetDto.description = formGroup.get('sensorDescription').value;

    if (!assetDto.sensor) {
      assetDto.tags = [AssetTags.sensor];
      assetDto.sensor = new SensorDto();
      assetDto.sensor.wantedConfiguration = new SensorConfigDto({ configurations: new SensorTypeDto(formGroup.get('sensorType').value).configurations });
      assetDto.sensor.currentConfiguration = new SensorConfigDto({ configurations: [] });
    }
    assetDto.sensor.sensorId = formGroup.get('sensorId').value;
    assetDto.sensor.type = formGroup.get('sensorType').value.id;
    this.applyFormValuesToSensorConfig(formGroup.get('SENSORCONFIG') as UntypedFormGroup, assetDto.sensor.wantedConfiguration.configurations);

    return assetDto;
  }

  private applyFormValuesToSensorConfig(formGroup: UntypedFormGroup, sensorConfig: SensorConfigValueDto[] = []): SensorConfigValueDto[] {
    _.forEach(formGroup.controls, (formControl: UntypedFormControl, key: string) => {
      const foundSensorConfig: SensorConfigValueDto = _.find(sensorConfig, (sensorConfigValue: SensorConfigValueDto) => sensorConfigValue.key === key);

      if (foundSensorConfig) {
        foundSensorConfig.value = formGroup.get(key).value;
      } else {
        sensorConfig.push(new SensorConfigValueDto({
          value: formGroup.get(key).value,
          key: key,
        }));
      }
    });
    return sensorConfig;
  }

  private createSensorTypeField(sensorTypes: SensorTypeDto[]): PhoenixFormDropdown<SensorTypeDto> {
    const entries: DropDownEntry<SensorTypeDto>[] = _.map(sensorTypes, (sensorType: SensorTypeDto) => ({
      value: sensorType,
      viewValue: sensorType.name,
    }));
    //console.log('entries', entries)

    return new PhoenixFormDropdown(
      this.formGroup,
      'sensorType',
      false,
      'WIZARDS.SENSOR.TYPE',
      entries,
      (e1: SensorTypeDto, e2: SensorTypeDto): boolean => (e1 && e2) ? e1.id === e2.id : false,

    );
  }

  private getElements(sensorTypes: SensorTypeDto[]): PhoenixFormElement[][] {
    let elements = [
      [new PhoenixFormText(this.formGroup, 'sensorName', 'WIZARDS.SENSOR.SENSORNAME', '', 'WIZARDS.SENSOR.SENSORNAMEHINT', 1, 64, true, false)],
      [new PhoenixFormTextarea(this.formGroup, 'sensorDescription', 'WIZARDS.SENSOR.SENSORDESCRIPTION', '', 'WIZARDS.SENSOR.SENSORDESCRIPTIONHINT', 1, 100, false, false)],
      [new PhoenixFormText(this.formGroup, 'sensorId', 'WIZARDS.SENSOR.SENSORID', '', 'WIZARDS.SENSOR.SENSORIDHINT', 1, 64, true, false)],
      [this.createSensorTypeField(sensorTypes)],
    ];
    return elements;
  }

  private forbiddenSensorIdValidator(ownSensorId: string): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      clearTimeout(this.debouncer);
      return Observable.create((observer: Observer<ValidationErrors | null>) => {
        this.debouncer = setTimeout(() => {
          const sc: SensorSearchCriteriaDto = new SensorSearchCriteriaDto({ sensorIds: [control.value] });
          this.sensorApiService.getSensors(sc).subscribe((next: AssetDto[]) => {
            const isOwnSensorId: AssetDto = _.find(next, { sensor: { sensorId: ownSensorId } });
            // tslint:disable-next-line
            const result = next.length === 0 || !_.isUndefined(isOwnSensorId) ? null : { 'forbiddenName': { value: control.value } };
            if (!_.isNull(result)) {
              this.snackbarService.openPhoenixSnackbar(4000, 'right', 'top', this.translateService.instant('WIZARDS.SENSOR.IDALREADYTAKEN'), 'red-snackbar');
            }
            observer.next(result);
            observer.complete();
          });
        }, 1000);
      });
    };
  }
}

