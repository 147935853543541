<div class="mt-12 mb-4 text-center">
  <div class="font-size-{{ iconSize }}"
    [ngClass]="phoenixFormControl.options['classes'] ? phoenixFormControl.options['classes'] : {}">
    <mat-icon class="notranslate" [ngClass]="{'icon-flipped': phoenixFormControl.options['flipped']}"
      [ngStyle]="{'color': phoenixFormControl.options['color']}" [inline]="true">{{ phoenixFormControl.value }}
    </mat-icon>
  </div>
  <div [ngClass]="{'h2': iconSize > 30, 'h4': iconSize <= 30}" class="h2" style="color: black;">
    {{ phoenixFormControl.label | translate }}
  </div>
  <div [ngClass]="{'h4': iconSize > 30, 'h6': iconSize <= 30}" class="h4 hint-text"
    *ngIf="phoenixFormControl.options['body']">
    {{ phoenixFormControl.options['body'] | translate }}
  </div>
</div>